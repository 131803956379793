import { useToggleURLQueryParams } from '@/utils/useToogleQueryParam'
import { useBreakpointValue } from '@chakra-ui/react'
import type Konva from 'konva'
import type { KonvaEventObject } from 'konva/lib/Node'
import { useEffect, useState } from 'react'

export const useReviewAreaWidth = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true })
  const sidebarWidth = isDesktop ? 300 : 0

  // Calculate width height based on the parent element
  const [_, setDimensions] = useState({
    width: document.documentElement.clientWidth - sidebarWidth,
    height: document.documentElement.clientHeight,
  })

  const updateDimensions = () => {
    setDimensions({
      width: document.documentElement.clientWidth - sidebarWidth,
      height: document.documentElement.clientHeight,
    })
  }

  useEffect(() => {
    window.visualViewport?.addEventListener('resize', updateDimensions)
    window.addEventListener('resize', updateDimensions)
    return () => {
      window.visualViewport?.addEventListener('resize', updateDimensions)
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  return {
    width: document.documentElement.clientWidth - sidebarWidth,
    height: document.documentElement.clientHeight,
  }
}

export const useKeyboardEvents = (stage?: Konva.Stage | null) => {
  const isMac = navigator.userAgent.toLowerCase().includes('mac')
  const shortcutKey = isMac ? 'Meta' : 'Alt'

  const { hasURLQueryParams, toggleURLQueryParam, deleteURLQueryParams } = useToggleURLQueryParams()
  const isCommentModeActive = hasURLQueryParams(['isCommentModeActive'])

  const handleKeyDown = (e: KeyboardEvent) => {
    const isKeyPressed = e.key === shortcutKey
    if (!isCommentModeActive && isKeyPressed) toggleURLQueryParam('isCommentModeActive', 'true')
    if (e.altKey || e.metaKey) stage?.draggable(false)
  }

  const handleKeyUp = (e: KeyboardEvent) => {
    const isKeyPressed = e.key === shortcutKey
    if (isCommentModeActive && isKeyPressed) deleteURLQueryParams(['isCommentModeActive'])
    if (isKeyPressed) stage?.draggable(true)
  }

  // Deactivate comment mode on reload/open page
  useEffect(() => {
    if (!isCommentModeActive) return
    deleteURLQueryParams(['isCommentModeActive'])
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [isCommentModeActive])

  return { isMac }
}

export const useZoom = (stage: Konva.Stage | null) => {
  const { handleWheel } = useZoomDesktop(stage)
  const { handleTouchZoomMove, handleTouchZoomEnd } = useZoomMobile(stage)

  return { handleWheel, handleTouchZoomMove, handleTouchZoomEnd }
}

const useZoomMobile = (stage: Konva.Stage | null, minScale = 0.2, maxScale = 3) => {
  const [lastDist, setLastDist] = useState(0)
  const [lastCenter, setLastCenter] = useState<{ x: number; y: number } | null>(null)

  const handleTouchZoomMove = (e: KonvaEventObject<TouchEvent>) => {
    if (e.evt.touches.length !== 2) return
    stage?.draggable(false)

    // Code below based on https://konvajs.org/docs/sandbox/Multi-touch_Scale_Stage.html
    const dist = getDistance(
      { x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY },
      { x: e.evt.touches[1].clientX, y: e.evt.touches[1].clientY },
    )
    if (!stage) return

    if (lastDist === 0) {
      setLastDist(dist)
      setLastCenter(
        getCenter(
          { x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY },
          { x: e.evt.touches[1].clientX, y: e.evt.touches[1].clientY },
        ),
      )
      return
    }

    const newCenter = getCenter(
      { x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY },
      { x: e.evt.touches[1].clientX, y: e.evt.touches[1].clientY },
    )

    // local coordinates of center point
    const pointTo = {
      x: (newCenter.x - stage.x()) / stage.scaleX(),
      y: (newCenter.y - stage.y()) / stage.scaleX(),
    }

    // Modified from original example to add a min and max scale
    let newScale = stage.scaleX() * (dist / lastDist)
    newScale = Math.max(minScale, Math.min(maxScale, newScale))

    stage.scaleX(newScale)
    stage.scaleY(newScale)

    if (!lastCenter) return

    // calculate new position of the stage
    const dx = newCenter.x - lastCenter.x
    const dy = newCenter.y - lastCenter.y

    const newPos = {
      x: newCenter.x - pointTo.x * newScale + dx,
      y: newCenter.y - pointTo.y * newScale + dy,
    }

    stage.position(newPos)
    stage.batchDraw()
    setLastDist(dist)
    setLastCenter(newCenter)
  }

  const handleTouchZoomEnd = () => {
    stage?.draggable(true)
    setLastDist(0)
    setLastCenter(null)
  }

  type Points = { x: number; y: number }
  const getCenter = (p1: Points, p2: Points) => {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    }
  }

  const getDistance = (p1: Points, p2: Points) => {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2))
  }

  return { handleTouchZoomMove, handleTouchZoomEnd }
}

const useZoomDesktop = (stage: Konva.Stage | null, minScale = 0.2, maxScale = 3) => {
  const handleWheel = (e: KonvaEventObject<WheelEvent>) => {
    e.evt.preventDefault()
    if (!stage) return
    const oldScale = stage?.scaleX()
    const pointer = stage.getPointerPosition()
    if (!pointer) return

    const scaleBy = 1.05
    let newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy

    // Enforce max and min scale
    newScale = Math.max(minScale, Math.min(maxScale, newScale))

    stage.scale({ x: newScale, y: newScale })

    const newPos = {
      x: pointer.x - (pointer.x - stage.x()) * (newScale / oldScale),
      y: pointer.y - (pointer.y - stage.y()) * (newScale / oldScale),
    }
    stage.position(newPos)
    stage.batchDraw()
  }

  return { handleWheel }
}

const checkIsTouchDevice = (): boolean => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

export const useTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(checkIsTouchDevice())

  useEffect(() => {
    const checkTouchDevice = () => {
      setIsTouchDevice(checkIsTouchDevice())
    }

    window.addEventListener('resize', checkTouchDevice)
    return () => {
      window.removeEventListener('resize', checkTouchDevice)
    }
  }, [])

  return isTouchDevice
}
