import { Grades } from '@/components/Grades'
import { Modal } from '@/components/modal'
import { usePutApiV1Essay, usePutApiV1EssayEssayIdSubmit } from '@/services/essay'
import { useGetApiV1ThemeThemeIdSupportMaterials } from '@/services/theme'
import type { ActivityViewModel, CompleteEssayViewModel, UpdateEssayDraftViewModel } from '@/services/types'
import useEssayStudentCreateStore from '@/store/useEssayStudentCreateTextStore'
import useNavigationStore from '@/store/useNavigationStore'
import { calculateTotalGrades } from '@/utils/calculateTotalGrades'
import { essayStatus, returnEssayStatusClassName } from '@/utils/essayStatus'
import {
  Avatar,
  Box,
  Button,
  Center,
  CloseButton,
  HStack,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { format } from 'date-fns'
import { IoIosSend } from 'react-icons/io'
import { LuPenSquare } from 'react-icons/lu'
import { MdOutlineImageNotSupported } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { EssayReviewSidebar, useEssayReviewSidebar } from '../../components/EssayArea/useEssayReviewSidebar'
import { maxCharsEssay, minCharsEssay } from './EssayStudentCreate'

type EssayDetailsProps = {
  activity: ActivityViewModel
  essay: CompleteEssayViewModel
  isEssayInProgress: boolean
}
export const EssaySidebar = (props: EssayDetailsProps) => {
  const { activity, essay, isEssayInProgress } = props
  const navigate = useNavigate()
  const { setReturnTo } = useNavigationStore()
  const { isSidebarOpen, toogleSidebar } = useEssayReviewSidebar()
  const queryClient = useQueryClient()

  const getApiV1ThemeIdSupportMaterials = useGetApiV1ThemeThemeIdSupportMaterials(activity.theme?.id || '')
  const supportMaterials = getApiV1ThemeIdSupportMaterials.data?.data.message

  const ableToSeeReview = (essay?.status ?? 0) >= 30

  const essayStudentCreateStore = useEssayStudentCreateStore()
  const totalCharsEssay = essayStudentCreateStore.draft.length

  const { isOpen: submitIsOpen, onOpen: submitOnOpen, onClose: submitOnClose } = useDisclosure()

  const putApiV1Essay = usePutApiV1Essay()
  const handleSaveDraft = () => {
    const data: UpdateEssayDraftViewModel = {
      essayId: essay?.id,
      draft: essayStudentCreateStore.draft,
      isText: true,
    }
    putApiV1Essay.mutate(
      { data },
      {
        onSuccess: () => toast.success('Rascunho salvo'),
        onError: (error) => toast.error(`Erro ao salvar rascunho: ${error.message}`),
      },
    )
  }

  const handleClearDraft = () => {
    const data: UpdateEssayDraftViewModel = {
      essayId: essay?.id,
      draft: '',
      isText: true,
    }
    putApiV1Essay.mutate(
      { data },
      {
        onSuccess: () => queryClient.invalidateQueries(),
        onError: (error) => toast.error(`Erro ao limpar rascunho: ${error.message}`),
      },
    )
  }

  const putApiV1EssayIdSubmit = usePutApiV1EssayEssayIdSubmit()
  const handleSubmitEssay = () => {
    if (essay.attachment) return changeEssayStatus()

    const dataDraft: UpdateEssayDraftViewModel = {
      essayId: essay?.id,
      draft: essayStudentCreateStore.draft,
      isText: true,
    }

    putApiV1Essay.mutate(
      { data: dataDraft },
      {
        onSuccess: changeEssayStatus,
        onError: (error) => toast.error(`Erro ao salvar rascunho antes do envio: ${error.message}`),
      },
    )
  }

  const changeEssayStatus = () => {
    putApiV1EssayIdSubmit.mutate(
      { essayId: essay?.id || '' },
      {
        onSuccess: () => {
          toast.success('Redação enviada para correção!')
          setReturnTo('/area-de-atividades')
          navigate(`/detalhamento-atividade/${activity.id}`)
        },
      },
    )
  }

  const submitEssay = () => {
    if (essay.attachment) return submitOnOpen()
    if (totalCharsEssay < minCharsEssay) return toast.warn(`A redação deve conter no mímino  ${minCharsEssay} caracteres.`)
    if (totalCharsEssay > maxCharsEssay) return toast.warn(`A redação deve conter no máximo ${maxCharsEssay} caracteres.`)
    submitOnOpen()
  }

  return (
    <Box
      width={{ base: '100%', md: '300px' }}
      minW={'300px'}
      height="100%"
      bgColor="white"
      right={0}
      top={0}
      overflowY="auto"
      display={{ base: isSidebarOpen ? 'block' : 'none', lg: 'flex' }}
      position={{ base: 'fixed', lg: 'static' }}
      p={7}
      flexDirection="column"
      zIndex={2}>
      <CloseButton
        display={{ base: 'block', lg: 'none' }}
        position="absolute"
        right={2}
        top={2}
        onClick={() => toogleSidebar(EssayReviewSidebar.DETAILS)}
      />
      <Stack width="100%" spacing={8}>
        <Stack spacing={1}>
          <Heading fontSize="sm">Status</Heading>
          <Center p={1} className={`${returnEssayStatusClassName(essay?.status)}`} w={'100%'}>
            <Text fontSize={'sm'}>{essayStatus[essay?.status].label}</Text>
          </Center>
        </Stack>

        <Stack spacing={1}>
          <Heading fontSize="sm">Atividade</Heading>
          <Text fontSize="sm" fontWeight="bold" color="gray.500">
            {activity?.subject}
          </Text>
          <Text fontSize="sm" color="gray.500">
            {activity.description}
          </Text>
        </Stack>

        <Stack spacing={1}>
          <Heading fontSize="sm">Turma</Heading>
          <Text fontSize="sm" color="gray.500">
            {activity?.groups?.[0].name || 'Turma não encontrada'}
          </Text>
        </Stack>

        <Stack spacing={1}>
          <Heading fontSize="sm">Tema</Heading>
          <Text fontSize="sm" color="gray.500">
            {activity.theme?.name || 'Tema não encontrado'}
          </Text>
        </Stack>

        <Stack spacing={1}>
          <Heading fontSize="sm">Entrega</Heading>
          <Text fontSize="sm" color="gray.500">
            {activity?.deadline && format(new Date(activity.deadline), 'dd/MM/yyyy')}
          </Text>
        </Stack>

        {supportMaterials?.length && (
          <Stack spacing={1}>
            <>
              <Heading fontSize="sm">Materiais de apoio</Heading>
              <VStack p={4} gap={4} borderRadius="md" backgroundColor="brand.500">
                {supportMaterials.map((supportMaterial) => (
                  <Link
                    key={supportMaterial.fileName}
                    color="gray.500"
                    gap={2}
                    href={supportMaterial.value || ''}
                    noOfLines={1}
                    display="flex"
                    fontSize="sm"
                    download>
                    <Image src={'/img/paper-upload.svg'} />
                    {supportMaterial.fileName}
                  </Link>
                ))}
              </VStack>
            </>
          </Stack>
        )}

        {ableToSeeReview && (
          <>
            <Stack spacing={1}>
              <Grades competencies={essay.competencies || []} totalGrade={calculateTotalGrades(essay.competencies || [])} />
            </Stack>
            <Stack spacing={1}>
              <Heading fontSize="xs">Corretor</Heading>
              {essay?.teacher?.name ? (
                <HStack>
                  <Avatar name={essay.teacher.name} />
                  <Text size="xs">{essay.teacher.name}</Text>
                </HStack>
              ) : (
                <HStack>
                  <Avatar name={activity?.school?.name || 'Nome do professor ou escola não encontrado'} />
                  <Text size="xs">{activity?.school?.name || 'Nome do professor ou escola não encontrado'}</Text>
                </HStack>
              )}
            </Stack>
          </>
        )}

        {isEssayInProgress && (
          <VStack width="100%">
            {!essay.attachment && (
              <Button
                width="100%"
                size="sm"
                leftIcon={<LuPenSquare fontSize={18} />}
                variant="outline"
                isDisabled={!essayStudentCreateStore.draft}
                isLoading={putApiV1Essay.isPending}
                onClick={handleSaveDraft}
                padding={5}>
                Salvar rascunho
              </Button>
            )}
            {essay.attachment && (
              <Button
                width="100%"
                size="sm"
                leftIcon={<MdOutlineImageNotSupported fontSize={18} />}
                variant="outline"
                isLoading={putApiV1Essay.isPending}
                onClick={handleClearDraft}
                padding={5}>
                Remover image
              </Button>
            )}
            <Button
              onClick={submitEssay}
              width="100%"
              variant="primary"
              leftIcon={<IoIosSend />}
              padding={6}
              isDisabled={putApiV1Essay.isPending}
              isLoading={putApiV1EssayIdSubmit.isPending}>
              Enviar para correção
            </Button>
          </VStack>
        )}
      </Stack>

      {isEssayInProgress && (
        <Modal
          title="Enviar redação"
          isOpen={submitIsOpen}
          onOpen={submitOnOpen}
          onClose={submitOnClose}
          onSubmit={handleSubmitEssay}
          isLoading={putApiV1Essay.isPending || putApiV1EssayIdSubmit.isPending}
          submitLabel="Enviar para correção"
          body="Uma vez que a redação é submetida para correção, você não poderá mais alterá-la. Deseja continuar?"
        />
      )}
    </Box>
  )
}
