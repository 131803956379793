/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-11-30 17:50:40 UTC)
 * OpenAPI spec version: v1
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import axios from 'axios'
import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios'
import type {
  BasicThemeViewModelResultViewModel,
  NewSupportMaterialViewModel,
  NewThemeViewModel,
  ProblemDetails,
  SupportMaterialViewModelIEnumerableResultViewModel,
  SupportMaterialViewModelResultViewModel,
  ThemeViewModelListResultViewModel,
  UpdateSupportMaterialViewModel,
  UpdateThemeImageViewModel,
  UpdateThemeViewModel
} from './types'




/**
 * @summary Adds a new Theme to be available to students
 */
export const postApiV1Theme = (
    newThemeViewModel: NewThemeViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/theme`,
      newThemeViewModel,options
    );
  }



export const getPostApiV1ThemeMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Theme>>, TError,{data: NewThemeViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1Theme>>, TError,{data: NewThemeViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1Theme>>, {data: NewThemeViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1Theme(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1ThemeMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1Theme>>>
    export type PostApiV1ThemeMutationBody = NewThemeViewModel
    export type PostApiV1ThemeMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Adds a new Theme to be available to students
 */
export const usePostApiV1Theme = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1Theme>>, TError,{data: NewThemeViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1Theme>>,
        TError,
        {data: NewThemeViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1ThemeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates basic data of a theme
 */
export const putApiV1Theme = (
    updateThemeViewModel: UpdateThemeViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/theme`,
      updateThemeViewModel,options
    );
  }



export const getPutApiV1ThemeMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1Theme>>, TError,{data: UpdateThemeViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1Theme>>, TError,{data: UpdateThemeViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1Theme>>, {data: UpdateThemeViewModel}> = (props) => {
          const {data} = props ?? {};

          return  putApiV1Theme(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1ThemeMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1Theme>>>
    export type PutApiV1ThemeMutationBody = UpdateThemeViewModel
    export type PutApiV1ThemeMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates basic data of a theme
 */
export const usePutApiV1Theme = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1Theme>>, TError,{data: UpdateThemeViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1Theme>>,
        TError,
        {data: UpdateThemeViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1ThemeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Updates theme image. If no image sent, it will be just removed from given theme
 */
export const putApiV1ThemeThemeIdImage = (
    themeId: string,
    updateThemeImageViewModel: UpdateThemeImageViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/theme/${themeId}/image`,
      updateThemeImageViewModel,options
    );
  }



export const getPutApiV1ThemeThemeIdImageMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1ThemeThemeIdImage>>, TError,{themeId: string;data: UpdateThemeImageViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1ThemeThemeIdImage>>, TError,{themeId: string;data: UpdateThemeImageViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1ThemeThemeIdImage>>, {themeId: string;data: UpdateThemeImageViewModel}> = (props) => {
          const {themeId,data} = props ?? {};

          return  putApiV1ThemeThemeIdImage(themeId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1ThemeThemeIdImageMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1ThemeThemeIdImage>>>
    export type PutApiV1ThemeThemeIdImageMutationBody = UpdateThemeImageViewModel
    export type PutApiV1ThemeThemeIdImageMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates theme image. If no image sent, it will be just removed from given theme
 */
export const usePutApiV1ThemeThemeIdImage = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1ThemeThemeIdImage>>, TError,{themeId: string;data: UpdateThemeImageViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1ThemeThemeIdImage>>,
        TError,
        {themeId: string;data: UpdateThemeImageViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1ThemeThemeIdImageMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Removes a theme
 */
export const deleteApiV1ThemeThemeId = (
    themeId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/v1/theme/${themeId}`,options
    );
  }



export const getDeleteApiV1ThemeThemeIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1ThemeThemeId>>, TError,{themeId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1ThemeThemeId>>, TError,{themeId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1ThemeThemeId>>, {themeId: string}> = (props) => {
          const {themeId} = props ?? {};

          return  deleteApiV1ThemeThemeId(themeId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1ThemeThemeIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1ThemeThemeId>>>
    
    export type DeleteApiV1ThemeThemeIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes a theme
 */
export const useDeleteApiV1ThemeThemeId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1ThemeThemeId>>, TError,{themeId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1ThemeThemeId>>,
        TError,
        {themeId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1ThemeThemeIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets the theme
 */
export const getApiV1ThemeThemeId = (
    themeId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<BasicThemeViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/theme/${themeId}`,options
    );
  }


export const getGetApiV1ThemeThemeIdQueryKey = (themeId: string,) => {
    return [`/api/v1/theme/${themeId}`] as const;
    }

    
export const getGetApiV1ThemeThemeIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1ThemeThemeId>>, TError = AxiosError<ProblemDetails | void>>(themeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeThemeId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1ThemeThemeIdQueryKey(themeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ThemeThemeId>>> = ({ signal }) => getApiV1ThemeThemeId(themeId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(themeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeThemeId>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetApiV1ThemeThemeIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ThemeThemeId>>>
export type GetApiV1ThemeThemeIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1ThemeThemeId<TData = Awaited<ReturnType<typeof getApiV1ThemeThemeId>>, TError = AxiosError<ProblemDetails | void>>(
 themeId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeThemeId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ThemeThemeId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiV1ThemeThemeId<TData = Awaited<ReturnType<typeof getApiV1ThemeThemeId>>, TError = AxiosError<ProblemDetails | void>>(
 themeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeThemeId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ThemeThemeId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiV1ThemeThemeId<TData = Awaited<ReturnType<typeof getApiV1ThemeThemeId>>, TError = AxiosError<ProblemDetails | void>>(
 themeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeThemeId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Gets the theme
 */

export function useGetApiV1ThemeThemeId<TData = Awaited<ReturnType<typeof getApiV1ThemeThemeId>>, TError = AxiosError<ProblemDetails | void>>(
 themeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeThemeId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetApiV1ThemeThemeIdQueryOptions(themeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Lists the theme from a school
 */
export const getApiV1ThemeSchoolSchoolId = (
    schoolId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<ThemeViewModelListResultViewModel>> => {
    
    return axios.get(
      `/api/v1/theme/school/${schoolId}`,options
    );
  }


export const getGetApiV1ThemeSchoolSchoolIdQueryKey = (schoolId: string,) => {
    return [`/api/v1/theme/school/${schoolId}`] as const;
    }

    
export const getGetApiV1ThemeSchoolSchoolIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1ThemeSchoolSchoolIdQueryKey(schoolId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>> = ({ signal }) => getApiV1ThemeSchoolSchoolId(schoolId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(schoolId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetApiV1ThemeSchoolSchoolIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>>
export type GetApiV1ThemeSchoolSchoolIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1ThemeSchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiV1ThemeSchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiV1ThemeSchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Lists the theme from a school
 */

export function useGetApiV1ThemeSchoolSchoolId<TData = Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>, TError = AxiosError<ProblemDetails | void>>(
 schoolId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeSchoolSchoolId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetApiV1ThemeSchoolSchoolIdQueryOptions(schoolId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Creates a new Support Material and attaches it to the given theme
 */
export const postApiV1ThemeSupportMaterial = (
    newSupportMaterialViewModel: NewSupportMaterialViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.post(
      `/api/v1/theme/support-material`,
      newSupportMaterialViewModel,options
    );
  }



export const getPostApiV1ThemeSupportMaterialMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1ThemeSupportMaterial>>, TError,{data: NewSupportMaterialViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof postApiV1ThemeSupportMaterial>>, TError,{data: NewSupportMaterialViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV1ThemeSupportMaterial>>, {data: NewSupportMaterialViewModel}> = (props) => {
          const {data} = props ?? {};

          return  postApiV1ThemeSupportMaterial(data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV1ThemeSupportMaterialMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV1ThemeSupportMaterial>>>
    export type PostApiV1ThemeSupportMaterialMutationBody = NewSupportMaterialViewModel
    export type PostApiV1ThemeSupportMaterialMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Creates a new Support Material and attaches it to the given theme
 */
export const usePostApiV1ThemeSupportMaterial = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV1ThemeSupportMaterial>>, TError,{data: NewSupportMaterialViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV1ThemeSupportMaterial>>,
        TError,
        {data: NewSupportMaterialViewModel},
        TContext
      > => {

      const mutationOptions = getPostApiV1ThemeSupportMaterialMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Returns all materials for a given theme
 */
export const getApiV1ThemeThemeIdSupportMaterials = (
    themeId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SupportMaterialViewModelIEnumerableResultViewModel>> => {
    
    return axios.get(
      `/api/v1/theme/${themeId}/support-materials`,options
    );
  }


export const getGetApiV1ThemeThemeIdSupportMaterialsQueryKey = (themeId: string,) => {
    return [`/api/v1/theme/${themeId}/support-materials`] as const;
    }

    
export const getGetApiV1ThemeThemeIdSupportMaterialsQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>, TError = AxiosError<ProblemDetails | void>>(themeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1ThemeThemeIdSupportMaterialsQueryKey(themeId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>> = ({ signal }) => getApiV1ThemeThemeIdSupportMaterials(themeId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(themeId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetApiV1ThemeThemeIdSupportMaterialsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>>
export type GetApiV1ThemeThemeIdSupportMaterialsQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1ThemeThemeIdSupportMaterials<TData = Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>, TError = AxiosError<ProblemDetails | void>>(
 themeId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiV1ThemeThemeIdSupportMaterials<TData = Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>, TError = AxiosError<ProblemDetails | void>>(
 themeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiV1ThemeThemeIdSupportMaterials<TData = Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>, TError = AxiosError<ProblemDetails | void>>(
 themeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Returns all materials for a given theme
 */

export function useGetApiV1ThemeThemeIdSupportMaterials<TData = Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>, TError = AxiosError<ProblemDetails | void>>(
 themeId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeThemeIdSupportMaterials>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetApiV1ThemeThemeIdSupportMaterialsQueryOptions(themeId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Updates a given material of a school. For performance purposes, the client needs to send if it changed the value
to avoid upload a file that wasn't changed.
 */
export const putApiV1ThemeSupportMaterialMaterialId = (
    materialId: string,
    updateSupportMaterialViewModel: UpdateSupportMaterialViewModel, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.put(
      `/api/v1/theme/support-material/${materialId}`,
      updateSupportMaterialViewModel,options
    );
  }



export const getPutApiV1ThemeSupportMaterialMaterialIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1ThemeSupportMaterialMaterialId>>, TError,{materialId: string;data: UpdateSupportMaterialViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof putApiV1ThemeSupportMaterialMaterialId>>, TError,{materialId: string;data: UpdateSupportMaterialViewModel}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiV1ThemeSupportMaterialMaterialId>>, {materialId: string;data: UpdateSupportMaterialViewModel}> = (props) => {
          const {materialId,data} = props ?? {};

          return  putApiV1ThemeSupportMaterialMaterialId(materialId,data,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PutApiV1ThemeSupportMaterialMaterialIdMutationResult = NonNullable<Awaited<ReturnType<typeof putApiV1ThemeSupportMaterialMaterialId>>>
    export type PutApiV1ThemeSupportMaterialMaterialIdMutationBody = UpdateSupportMaterialViewModel
    export type PutApiV1ThemeSupportMaterialMaterialIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Updates a given material of a school. For performance purposes, the client needs to send if it changed the value
to avoid upload a file that wasn't changed.
 */
export const usePutApiV1ThemeSupportMaterialMaterialId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof putApiV1ThemeSupportMaterialMaterialId>>, TError,{materialId: string;data: UpdateSupportMaterialViewModel}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof putApiV1ThemeSupportMaterialMaterialId>>,
        TError,
        {materialId: string;data: UpdateSupportMaterialViewModel},
        TContext
      > => {

      const mutationOptions = getPutApiV1ThemeSupportMaterialMaterialIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Gets the support material. If is file will receive back the file itself in base64 format
 */
export const getApiV1ThemeSupportMaterialMaterialId = (
    materialId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<SupportMaterialViewModelResultViewModel>> => {
    
    return axios.get(
      `/api/v1/theme/support-material/${materialId}`,options
    );
  }


export const getGetApiV1ThemeSupportMaterialMaterialIdQueryKey = (materialId: string,) => {
    return [`/api/v1/theme/support-material/${materialId}`] as const;
    }

    
export const getGetApiV1ThemeSupportMaterialMaterialIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>, TError = AxiosError<ProblemDetails | void>>(materialId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>, TError, TData>>, axios?: AxiosRequestConfig}
) => {

const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV1ThemeSupportMaterialMaterialIdQueryKey(materialId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>> = ({ signal }) => getApiV1ThemeSupportMaterialMaterialId(materialId, { signal, ...axiosOptions });

      

      

   return  { queryKey, queryFn, enabled: !!(materialId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>, TError, TData> & { queryKey: DataTag<QueryKey, TData> }
}

export type GetApiV1ThemeSupportMaterialMaterialIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>>
export type GetApiV1ThemeSupportMaterialMaterialIdQueryError = AxiosError<ProblemDetails | void>


export function useGetApiV1ThemeSupportMaterialMaterialId<TData = Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>, TError = AxiosError<ProblemDetails | void>>(
 materialId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiV1ThemeSupportMaterialMaterialId<TData = Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>, TError = AxiosError<ProblemDetails | void>>(
 materialId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>,
          TError,
          TData
        > , 'initialData'
      >, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
export function useGetApiV1ThemeSupportMaterialMaterialId<TData = Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>, TError = AxiosError<ProblemDetails | void>>(
 materialId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> }
/**
 * @summary Gets the support material. If is file will receive back the file itself in base64 format
 */

export function useGetApiV1ThemeSupportMaterialMaterialId<TData = Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>, TError = AxiosError<ProblemDetails | void>>(
 materialId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getApiV1ThemeSupportMaterialMaterialId>>, TError, TData>>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {

  const queryOptions = getGetApiV1ThemeSupportMaterialMaterialIdQueryOptions(materialId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Removes a support material
 */
export const deleteApiV1ThemeSupportMaterialMaterialId = (
    materialId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    
    return axios.delete(
      `/api/v1/theme/support-material/${materialId}`,options
    );
  }



export const getDeleteApiV1ThemeSupportMaterialMaterialIdMutationOptions = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1ThemeSupportMaterialMaterialId>>, TError,{materialId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1ThemeSupportMaterialMaterialId>>, TError,{materialId: string}, TContext> => {
const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiV1ThemeSupportMaterialMaterialId>>, {materialId: string}> = (props) => {
          const {materialId} = props ?? {};

          return  deleteApiV1ThemeSupportMaterialMaterialId(materialId,axiosOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type DeleteApiV1ThemeSupportMaterialMaterialIdMutationResult = NonNullable<Awaited<ReturnType<typeof deleteApiV1ThemeSupportMaterialMaterialId>>>
    
    export type DeleteApiV1ThemeSupportMaterialMaterialIdMutationError = AxiosError<ProblemDetails | void>

    /**
 * @summary Removes a support material
 */
export const useDeleteApiV1ThemeSupportMaterialMaterialId = <TError = AxiosError<ProblemDetails | void>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteApiV1ThemeSupportMaterialMaterialId>>, TError,{materialId: string}, TContext>, axios?: AxiosRequestConfig}
): UseMutationResult<
        Awaited<ReturnType<typeof deleteApiV1ThemeSupportMaterialMaterialId>>,
        TError,
        {materialId: string},
        TContext
      > => {

      const mutationOptions = getDeleteApiV1ThemeSupportMaterialMaterialIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    