import FontsUtils from '@/theme/foundations/FontsUtils'
import { Box, Button, HStack } from '@chakra-ui/react'
import { useSearchParams } from 'react-router-dom'

export type SelectedTab = 'todas' | 'em-andamento' | 'finalizadas'

const tabs = [
  { id: 'todas', label: 'Todas' },
  { id: 'em-andamento', label: 'Em andamento' },
  { id: 'finalizadas', label: 'Finalizadas' },
]

export const ActivityAreaTabs = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleSelectedTab = (tabId: SelectedTab) => {
    setSearchParams({ tabId })
  }
  const selectedTabIdUrl = (searchParams.get('tabId') as SelectedTab) || 'todas'

  return (
    <HStack maxW="3xl" gap={0} height="100%" width="100%">
      {tabs.map((tab) => (
        <Box
          key={tab.id}
          width="100%"
          py={2}
          textAlign="center"
          borderBottom={selectedTabIdUrl === tab.id ? '2px' : '1px'}
          borderColor={selectedTabIdUrl === tab.id ? '#02e39f' : 'gray.200'}
          onClick={() => handleSelectedTab(tab.id as SelectedTab)}>
          <Button
            variant="ghost"
            width="100%"
            size="sm"
            color={FontsUtils.fontGray.color}
            fontFamily={FontsUtils.fontPrimary.fontFamily}
            fontWeight={selectedTabIdUrl === tab.id ? 'bold' : 'normal'}>
            {tab.label}
          </Button>
        </Box>
      ))}
    </HStack>
  )
}
