import {
  Button,
  Center,
  HStack,
  Image,
  Popover,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useState } from 'react'
import { deleteInstitutionApi } from '../../apis/institutionApi/institutionApi'
import { institutionGradeTypeLabels } from '../../utils/institutionGradeTypes'
import './InstitutionCard.css'
import GetModalCreateCompetency from '../GetModalCreateCompetency'
import GetModalDeleteCompetency from '../GetModalDeleteCompetency'
import GetModalEditCompetency from '../GetModalEditCompetency'
import GetModalEditInstitution from '../GetModalEditInstitution'
import CompetenciesList from './CompetenciesList'

function InstitutionCard(props) {
  const { itemData, parentProps } = props

  const { fetchAllInstitutions, schoolId } = parentProps

  const popoverDisclosure = useDisclosure()
  const modalEditDisclosure = useDisclosure()

  const modalDeleteCompetencyDisclosure = useDisclosure()
  const modalEditCompetencyDisclosure = useDisclosure()
  const modalCreateCompetencyDisclosure = useDisclosure()

  const [editCompetency, setEditCompetency] = useState({})

  const [loading, setLoading] = useState(false)

  return (
    <VStack borderRadius="lg" align="start" alignContent="center" overflow="hidden" boxShadow="xl">
      <div className="institution-inner">
        <div className="title-link-area-de-institutions">
          <HStack justifyContent={'space-between'} width={'100%'} display={'flex'} direction={'column'} marginLeft={'16px'}>
            <VStack alignItems={'start'}>
              <Text alignSelf={'start'} className="publicsans-bold-absolute-zero-16px">
                {itemData.name}
              </Text>
              <Spacer />
              <HStack className="frame-2606-detalhamento-institution" gap={'20px'}>
                <div>
                  <div className="publicsans-bold-nevada-10px">Forma de avaliacao</div>
                  <div>{institutionGradeTypeLabels[itemData.institutionGradeType]}</div>
                </div>
                <div>
                  <div className="publicsans-bold-nevada-10px">Nota máxima</div>
                  <div> {itemData.maxGrade} </div>
                </div>
              </HStack>
            </VStack>
            <Spacer />
            <VStack width={'15%'} alignItems={'end'}>
              <img
                className="mode-edit-area-de-institution"
                src="/img/mode-edit@2x.png"
                alt="Mode edit"
                onClick={() => {
                  modalEditDisclosure.onOpen()
                }}
              />
              {modalEditDisclosure.isOpen && (
                <GetModalEditInstitution
                  itemData={itemData}
                  loading={loading}
                  setLoading={setLoading}
                  fetchAllInstitutions={fetchAllInstitutions}
                  modalEditDisclosure={modalEditDisclosure}
                  schoolId={schoolId}
                />
              )}
              <Spacer />
              <Popover
                isOpen={popoverDisclosure.isOpen}
                onOpen={popoverDisclosure.onOpen}
                onClose={popoverDisclosure.onClose}
                placement="bottom-end"
                variant="responsive"
                closeOnBlur={false}>
                <PopoverTrigger>
                  <Image src={'/img/deleteIcon.svg'} pr={3} cursor={'pointer'} />
                </PopoverTrigger>
                <PopoverContent width="auto" mx={3} maxW={'300px'} mr={4} borderRadius="lg">
                  <PopoverHeader>
                    <Text fontSize="xl" color="brand.200">
                      Confirme Remoção
                    </Text>
                  </PopoverHeader>
                  <PopoverFooter alignSelf={'center'} width={'100%'}>
                    <HStack width={'100%'} flexDir={{ base: 'column-reverse', md: 'row' }}>
                      <Button
                        variant={'outline'}
                        height={'48px'}
                        width={'100%'}
                        isDisabled={loading}
                        onClick={popoverDisclosure.onClose}>
                        Cancelar
                      </Button>
                      <Button
                        colorScheme={'red'}
                        width={'100%'}
                        height={'48px'}
                        isLoading={loading}
                        onClick={async () => {
                          try {
                            setLoading(true)
                            await deleteInstitutionApi(itemData.id, schoolId)
                            await fetchAllInstitutions()
                          } finally {
                            setLoading(false)
                          }
                        }}>
                        Remover
                      </Button>
                    </HStack>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            </VStack>
          </HStack>
        </div>
        <VStack p={6} gap={6} bgColor="white" height="100%" width="100%">
          <VStack w="100%" h="100%" align="start">
            {itemData.competencies?.length > 0 ? (
              <>
                <div className="competencias-vinculadas-area-de-institutions publicsans-normal-nevada-16px">
                  Competências vinculadas:
                </div>
                <CompetenciesList
                  itemData={itemData}
                  modalEditCompetencyDisclosure={modalEditCompetencyDisclosure}
                  setEditCompetency={setEditCompetency}
                  modalDeleteCompetencyDisclosure={modalDeleteCompetencyDisclosure}
                />
                <Spacer />
              </>
            ) : (
              <Center width="100%" height="100%" flex={1} p={6}>
                <Text color="gray.400">Adicionar competências</Text>
              </Center>
            )}
            <Button variant={'primary'} height={'48px'} width={'100%'} onClick={modalCreateCompetencyDisclosure.onOpen}>
              <HStack w={'100%'} justifyContent={'center'}>
                <img className="ic-add-competencia" src="/img/ic-add-6.svg" alt="ic-add" />
                <Text> {'Criar Competência'} </Text>
              </HStack>
            </Button>
            {modalEditCompetencyDisclosure.isOpen && (
              <GetModalEditCompetency
                editCompetency={editCompetency}
                fetchAllInstitutions={fetchAllInstitutions}
                modalEditCompetencyDisclosure={modalEditCompetencyDisclosure}
                schoolId={schoolId}
              />
            )}
            <GetModalDeleteCompetency
              modalDeleteCompetencyDisclosure={modalDeleteCompetencyDisclosure}
              editCompetency={editCompetency}
              setEditCompetency={setEditCompetency}
              fetchAllInstitutions={fetchAllInstitutions}
              schoolId={schoolId}
              loading={loading}
              setLoading={setLoading}
            />
            <GetModalCreateCompetency
              modalCreateCompetencyDisclosure={modalCreateCompetencyDisclosure}
              itemData={itemData}
              schoolId={schoolId}
              fetchAllInstitutions={fetchAllInstitutions}
              loading={loading}
              setLoading={setLoading}
            />
          </VStack>
        </VStack>
      </div>
    </VStack>
  )
}

export default InstitutionCard
