/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-11-30 17:50:40 UTC)
 * OpenAPI spec version: v1
 */
import type { EssayCommentType } from './essayCommentType';
import type { ParentCommentViewModel } from './parentCommentViewModel';
import type { KeyValueViewModel } from './keyValueViewModel';

export interface CommentViewModel {
  /** @nullable */
  comment?: string | null;
  commentType?: EssayCommentType;
  /** @nullable */
  competencyId?: string | null;
  createdAt?: string;
  /** @nullable */
  endPos?: number | null;
  /** @nullable */
  endPosX?: number | null;
  /** @nullable */
  endPosY?: number | null;
  id?: string;
  /** @nullable */
  lineHeight?: number | null;
  parent?: ParentCommentViewModel;
  /** @nullable */
  startPos?: number | null;
  /** @nullable */
  startPosX?: number | null;
  /** @nullable */
  startPosY?: number | null;
  /** @nullable */
  readonly timeFormatted?: string | null;
  user?: KeyValueViewModel;
}
