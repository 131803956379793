import HighlightIcon from '@/assets/highlight-on.svg'
import { EssayReviewSidebar, useEssayReviewSidebar } from '@/components/EssayArea/useEssayReviewSidebar'
import { useToggleURLQueryParams } from '@/utils/useToogleQueryParam'
import {
  Box,
  HStack,
  IconButton,
  Image,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Tooltip,
} from '@chakra-ui/react'
import type { ReactNode } from 'react'
import { CgDetailsMore } from 'react-icons/cg'
import { FaHighlighter } from 'react-icons/fa'
import { MdOutlineComment } from 'react-icons/md'
import { RxBorderWidth } from 'react-icons/rx'

export type MarkerProps = {
  size: number
  setSize: (lineHeight: number) => void
}
export const MarkerButton = (props: MarkerProps) => {
  const { size, setSize } = props
  return (
    <Popover placement="left">
      <Tooltip label="Tamanho do marcador" placement="left">
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton
              pointerEvents="auto"
              size="md"
              shadow="md"
              aria-label="Marcador"
              isRound
              icon={<RxBorderWidth fontSize="20px" />}
              _hover={{ color: 'brand.200' }}
              bgColor="white"
              color="gray.500"
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <Portal>
        <PopoverContent>
          <PopoverBody>
            <HStack>
              <Slider min={15} max={120} step={5} defaultValue={size} onChange={(val) => setSize(val)}>
                <SliderTrack>
                  <SliderFilledTrack bgColor="brand.200" />
                </SliderTrack>
                <SliderThumb bgColor="brand.200" />
              </Slider>
              <Box>{size}</Box>
            </HStack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

type GeneralCommentProps = {
  children: ReactNode
}

export const GeneralCommentButton = (props: GeneralCommentProps) => {
  const { children } = props

  return (
    <Popover placement="left-end">
      <Tooltip label="Comentários gerais" placement="left">
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton
              pointerEvents="auto"
              isRound
              shadow="md"
              aria-label="Comentários gerais"
              size="md"
              icon={<MdOutlineComment fontSize="24px" />}
              _hover={{ color: 'brand.200' }}
              bgColor="white"
              color="gray.500"
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>

      <Portal>
        <PopoverContent>
          <PopoverBody>
            <PopoverCloseButton />
            {children}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}

export const DetailsButton = () => {
  const { toogleSidebar, isCurrentSideBarOpen } = useEssayReviewSidebar()
  return (
    <Tooltip label="Detalhes" placement="left">
      <IconButton
        pointerEvents="auto"
        display={{ base: 'flex', lg: 'none' }}
        isRound
        shadow="md"
        aria-label="Detalhes"
        size="md"
        icon={<CgDetailsMore fontSize="24px" />}
        _hover={{ color: 'brand.200' }}
        bgColor="white"
        color="gray.500"
        onClick={() => toogleSidebar(EssayReviewSidebar.DETAILS)}
        isActive={isCurrentSideBarOpen(EssayReviewSidebar.DETAILS)}
      />
    </Tooltip>
  )
}

export const CommentButton = () => {
  const { toggleURLQueryParam, hasURLQueryParams } = useToggleURLQueryParams()
  const isCommentModeActive = hasURLQueryParams(['isCommentModeActive'])
  const tooltipText = `${isCommentModeActive ? 'Desativar' : 'Ativar'} modo comentário`

  return (
    <Tooltip label={tooltipText} placement="left">
      <IconButton
        pointerEvents="auto"
        shadow="md"
        size="lg"
        aria-label="Modo comentário"
        isRound
        width="56px"
        height="56px"
        icon={isCommentModeActive ? <Image src={HighlightIcon} alt="comentários" /> : <FaHighlighter fontSize="20px" />}
        bgColor={isCommentModeActive ? 'brand.200' : 'white'}
        _hover={{ color: 'brand.200' }}
        _active={{ color: 'brand.200' }}
        color="brand.200"
        onClick={() => toggleURLQueryParam('isCommentModeActive', 'true')}
      />
    </Tooltip>
  )
}
