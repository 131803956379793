import { useEffect, useState } from 'react'
import '../ActivityDetails.css'
import { useAddToHistory } from '@/utils/useNavigationHooks'
import { Box, Container, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { getStudentActivityByIdApi } from '../../../apis/activitityApi/activityApi'
import { listSupportMaterialApi } from '../../../apis/themeApi/supportMaterialApi'
import { getThemeApi } from '../../../apis/themeApi/themeApi'
import DateWithCalendarIcon from '../../../components/DateWithCalendarIcon'
import PageSpinner from '../../../components/PageSpinner'
import SharedTablePagination from '../../../components/SharedTablePagination'
import SupportMaterialList from '../../../components/SupportMaterialList'
import useNavigationStore from '../../../store/useNavigationStore'
import { formatDate } from '../../../utils/formatDate'
import { HandleEssayClickStudent, HandleStatusInput, HandleSubmittedDate } from '../../../utils/sharedTableColumnHandlers'
import { CreateEssay } from './CreateEssay'

function ActivityDetailsStudent() {
  const navigate = useNavigate()
  const { setReturnTo } = useNavigationStore()
  const { atividadeID } = useParams()
  const [activityData, setActivityData] = useState({})
  const [supportMaterialList, setSupportMaterialList] = useState([])
  const [theme, setTheme] = useState({})
  const [loading, setLoading] = useState(true)
  const [essays, setEssays] = useState([])
  const [canWriteEssay, setCanWriteEssay] = useState(false)

  const fetchOptions = () => {
    return new Promise((resolve) => {
      Promise.all([getStudentActivityByIdApi(atividadeID)]).then(([activityResult]) => {
        Promise.all([listSupportMaterialApi(activityResult.theme.id), getThemeApi(activityResult.theme.id)]).then(
          ([supportMaterialResult, themeResult]) => {
            resolve({ activityResult, supportMaterialResult, themeResult })
          },
        )
      })
    })
  }

  useEffect(() => {
    fetchOptions().then(({ activityResult, supportMaterialResult, themeResult }) => {
      setActivityData(activityResult)
      setSupportMaterialList(supportMaterialResult)
      setTheme(themeResult)
      setEssays(activityResult.essays)
      setLoading(false)

      const currentDate = new Date()
      const deadlineDate = activityResult.deadline ? new Date(activityResult.deadline) : null
      const canWrite = deadlineDate ? currentDate <= deadlineDate : false
      setCanWriteEssay(canWrite)
    })
  }, [])

  useAddToHistory()

  function Data(props) {
    const { date } = props

    return (
      <div className="data-detalhamento-atividade">
        <img
          className="calendar-2851774-detalhamento-atividade"
          src={'/img/calendar-2851774-5@2x.png'}
          alt="calendar-2851774"
        />
        <div className="date-detalhamento-atividade publicsans-normal-nevada-14px">{date === '' ? '' : formatDate(date)}</div>
      </div>
    )
  }

  function navigationActivityStudent(link) {
    setReturnTo(`/detalhamento-atividade/${atividadeID}`)
    navigate(link)
  }

  if (loading) return <PageSpinner />

  return (
    <HStack
      width={'100%'}
      maxW={'container.xl'}
      px={5}
      flexDir={{ md: 'row', base: 'column' }}
      gap={'16px'}
      justifyContent={'space-between'}
      className={'content-max-size'}>
      <VStack gap={5} className={'detalhamento-atividade-body'}>
        <Box className="atividade-detalhamento-atividade">
          {theme?.base64Image && (
            <Image minW="150px" hideBelow={'md'} className={'activity-image-style'} src={theme?.base64Image} />
          )}
          <VStack className="frame-2593-detalhamento-atividade">
            <HStack width={'100%'} alignItems={'start'}>
              <VStack width={'100%'} flex={1} gap={0} alignItems={'flex-start'}>
                <Text className="publicsans-bold-nevada-14px">Atividade</Text>
                <Text
                  fontSize={{ md: '24px', base: '18px' }}
                  wordBreak="break-word"
                  className="atividade-iii-unidade-2-detalhamento-atividade publicsans-normal-absolute-zero-24px">
                  {activityData.subject}
                </Text>
              </VStack>
              <CreateEssay activity={activityData} navigateTo={navigationActivityStudent} isHidden={!canWriteEssay} />
            </HStack>
            <VStack gap={5} className="frame-2592-detalhamento-atividade">
              <div className="tema-detalhamento-atividade">
                <div className="publicsans-bold-nevada-10px">TEMA</div>
                <p
                  className="theme-name-detalhamento-atividade publicsans-normal-nevada-14px"
                  onClick={() => navigationActivityStudent(`/detalhamento-tema/${activityData.theme.id}`)}>
                  {activityData.theme.name}
                </p>
              </div>
              <HStack
                flexDir={{ md: 'row', base: 'column' }}
                alignItems={'start'}
                gap={3}
                className="frame-2606-detalhamento-atividade">
                <VStack alignItems={'start'} className="entrega-detalhamento-atividade">
                  <div className="publicsans-bold-nevada-10px">CRIAÇÃO</div>
                  <Data date={activityData.createdAt} />
                </VStack>
                <VStack alignItems={'start'} className="entrega-detalhamento-atividade">
                  <div className=" publicsans-bold-nevada-10px">INICIO</div>
                  <Data date={activityData.validFrom} />
                </VStack>
                <VStack alignItems={'start'} className="entrega-1-detalhamento-atividade">
                  <div className="publicsans-bold-nevada-10px">DATA FINAL</div>
                  <DateWithCalendarIcon
                    imagePath={'/img/calendar-icon.svg'}
                    date={new Date(activityData.deadline)}
                    shouldHighlight={true}
                    size={'18px'}
                  />
                </VStack>
              </HStack>
            </VStack>
          </VStack>
        </Box>
        <VStack width={'100%'} gap={5}>
          {essays.length ? (
            <VStack width={'100%'} gap={3}>
              <Text fontSize={{ md: '24px', base: '20px' }} className={'title-detalhe-atividade'}>
                Status
              </Text>

              <SharedTablePagination
                className={''}
                data={essays}
                itemsPerPage={5}
                columns={[
                  {
                    title: 'Data de Entrega',
                    render: (itemData) => <HandleSubmittedDate dateSubmitted={itemData.dateSubmitted} />,
                  },
                  {
                    title: 'Status',
                    render: (itemData) => <HandleStatusInput status={itemData.status} />,
                  },
                  {
                    title: 'Tema',
                    render: (_itemData) => (
                      <div className={'table-cell-container publicsans-normal-black-14px'}>{activityData.theme.name}</div>
                    ),
                  },
                  {
                    title: 'Corretor',
                    render: (itemData) => (
                      <div className={'table-cell-container publicsans-normal-nevada-14px'}>
                        <Text noOfLines={1}>{itemData.teacher.name}</Text>
                      </div>
                    ),
                  },
                  {
                    title: 'Detalhes',
                    render: (itemData) => <HandleEssayClickStudent essayId={itemData.id} />,
                  },
                ]}
              />
            </VStack>
          ) : (
            ''
          )}
          <Text fontSize={{ md: '24px', base: '20px' }} className={'title-detalhe-atividade'}>
            Descrição da atividade
          </Text>
          <Container
            backgroundColor={'#f3f5ff'}
            width={'100%'}
            borderRadius={'12px'}
            minHeight={'100px'}
            maxWidth={'100%'}
            textAlign={activityData.description ? 'start' : 'center'}>
            <Text pt={3} pb={3} className={'publicsans-normal-nevada-16px'}>
              {activityData.description ? activityData.description : ''}
            </Text>
          </Container>
          <VStack width={'100%'} gap={3}>
            <Text fontSize={{ md: '24px', base: '20px' }} className={'title-detalhe-atividade'}>
              Material de apoio do tema
            </Text>
            {supportMaterialList.length === 0 ? (
              <Container
                backgroundColor={'#f3f5ff'}
                width={'100%'}
                borderRadius={'12px'}
                minHeight={'100px'}
                maxWidth={'100%'}
                marginBottom={5}
                textAlign={'center'}>
                <Text marginTop={'35px'} className={'publicsans-normal-nevada-16px'}>
                  Não há material de apoio para o tema desta atividade.
                </Text>
              </Container>
            ) : (
              <VStack width={'100%'} marginBottom={5}>
                <SupportMaterialList supportMaterialList={supportMaterialList} deleteType={'detalhamentoAtividade'} />
              </VStack>
            )}
          </VStack>
        </VStack>
      </VStack>
    </HStack>
  )
}

export default ActivityDetailsStudent
