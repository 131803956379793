import { EssayCreateUploadImage } from '@/components/EssayArea/EssayCreateUploadImage'
import { useReviewAreaWidth } from '@/components/EssayArea/EssayReviewArea.hooks'
import { EssayImageReview } from '@/components/EssayArea/EssayReviewArea/EssayImageReview'
import { usePutApiV1Essay } from '@/services/essay'
import {
  ActivityType,
  type ActivityViewModel,
  type CompleteEssayViewModel,
  type UpdateEssayDraftViewModel,
} from '@/services/types'
import useEssayStudentCreateStore from '@/store/useEssayStudentCreateTextStore'
import { toBase64 } from '@/utils/toBase64'
import { Box, Stack, Text, Textarea } from '@chakra-ui/react'
import { useQueryClient } from '@tanstack/react-query'
import { type FormEvent, useEffect } from 'react'
import TextareaAutosize from 'react-textarea-autosize'

type EssayCreateTextProps = {
  essay?: CompleteEssayViewModel
  activity?: ActivityViewModel
}

const paddingX = 40
export const minCharsEssay = 100
export const maxCharsEssay = 6000

export const EssayStudentCreate = (props: EssayCreateTextProps) => {
  const { essay, activity } = props
  const queryClient = useQueryClient()
  const essayStudentCreateStore = useEssayStudentCreateStore()
  const totalCharsEssay = essayStudentCreateStore.draft.length
  const dimensions = useReviewAreaWidth()

  const handleInput = (e: FormEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement
    essayStudentCreateStore.setDraft(target.value)

    // Insert text in parent element to increase height and show scroll
    const parent = target.parentNode as HTMLElement
    if (parent) {
      parent.dataset.replicatedValue = target.value
    }
  }

  const putApiV1Essay = usePutApiV1Essay()

  // on load page set draft on the store
  useEffect(() => {
    essayStudentCreateStore.setDraft(essay?.draft || '')
  }, [essay?.draft])

  // image
  const onDropImage = async (files: File) => {
    const newDropedFile = {
      id: String(files.lastModified),
      preview: URL.createObjectURL(files),
      base64: await toBase64(files),
    }

    const data: UpdateEssayDraftViewModel = {
      essayId: essay?.id,
      draft: newDropedFile.base64,
      isText: false,
    }
    putApiV1Essay.mutate({ data }, { onSuccess: () => queryClient.invalidateQueries() })
  }

  if (essay?.attachment) {
    return (
      <Stack flex="1" maxH="100%" alignItems="center" overflowY="auto">
        <EssayImageReview imageUrl={essay.attachment || ''} isViewOnlyMode={true} />
      </Stack>
    )
  }

  return (
    <Stack flex="1" maxH="100%" alignItems="center" overflowY="auto">
      <Box className="grow-wrap">
        <Textarea
          as={TextareaAutosize}
          width={dimensions.width - paddingX}
          _focusVisible={{ outline: 'none' }}
          maxWidth="800px"
          lineHeight="200%"
          value={essayStudentCreateStore.draft}
          onChange={handleInput}
          fontSize="xl"
          aria-placeholder="test"
          overflow="hidden"
          autoFocus={true}
          outline="none"
          border="none"
          resize="none"
          px={0}
          py={20}
          zIndex={1}
        />
      </Box>

      {!essayStudentCreateStore.draft.length && (
        <Box
          display="flex"
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
          maxWidth="800px"
          py={20}
          zIndex={1}
          gap={2}
          pointerEvents="none"
          flexWrap="wrap"
          width={dimensions.width - paddingX}>
          <Text color="gray.400" fontSize="xl" userSelect="none" lineHeight="200%">
            Digite aqui sua redação
          </Text>
          {activity?.activityType === ActivityType.NUMBER_2 && (
            <EssayCreateUploadImage isLoading={putApiV1Essay.isPending} onDrop={onDropImage} />
          )}
        </Box>
      )}

      <Box
        pos="absolute"
        bgColor={totalCharsEssay > maxCharsEssay ? 'red.100' : 'gray.100'}
        px={3}
        borderRadius="lg"
        right={8}
        bottom={8}
        zIndex={1}
        opacity={0.9}>
        <Text fontSize="sm" color={totalCharsEssay > maxCharsEssay ? 'red.500' : 'gray.500'}>
          {totalCharsEssay}/{maxCharsEssay} caracteres
        </Text>
      </Box>
    </Stack>
  )
}
