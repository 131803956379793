import '../../../globals.css'
import './SignUpPage.css'
import SignUpStep1 from '@/pages/SignUpPage/SignUpStep1.tsx'
import SignUpStep2 from '@/pages/SignUpPage/SignUpStep2.tsx'
import SignUpStep3Adm from '@/pages/SignUpPage/SignUpStep3Adm.tsx'
import SignUpStep3IT from '@/pages/SignUpPage/SignUpStep3IT.tsx'
import SignUpStep4 from '@/pages/SignUpPage/SignUpStep4.tsx'
import { VStack } from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import HeaderLogin from '../../components/LoginHeader'

export type UserData = {
  name?: string
  email?: string
  phoneNumber?: string
  eula?: boolean
  userType?: 'Professor' | 'GestorEscolar'
}

const SignUpPage = () => {
  const [currentStep, setCurrentStep] = useState(1)
  const [userData, setUserData] = useState<UserData>({})
  const navigate = useNavigate()

  const handleNextStep = (data?: UserData) => {
    if (currentStep === 4) {
      setUserData({})
      navigate('/login')
    } else {
      setUserData({ ...userData, ...data })
      setCurrentStep((prevCurrentStep) => prevCurrentStep + 1)
    }
  }

  const handlePreviousStep = () => {
    if (currentStep === 4 || currentStep === 1) {
      navigate('/login')
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <SignUpStep1 handlingNextStep={handleNextStep} />
      case 2:
        return <SignUpStep2 handleReturn={handlePreviousStep} handlingNextStep={handleNextStep} userData={userData} />
      case 3:
        return userData.userType === 'Professor' ? (
          <SignUpStep3IT userData={userData} handleReturn={handlePreviousStep} handlingNextStep={handleNextStep} />
        ) : (
          <SignUpStep3Adm handleReturn={handlePreviousStep} handlingNextStep={handleNextStep} userData={userData} />
        )
      case 4:
        return <SignUpStep4 handlingNextStep={handleNextStep} />
      // Add more cases for additional steps
      default:
        return null
    }
  }

  return (
    <VStack height={'100%'} gap={0} width={'100%'}>
      <HeaderLogin />
      <div className={'height-fixed'}>{renderStepContent()}</div>
    </VStack>
  )
}

export default SignUpPage
