import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'

export function ThemeAIAssistantModal({ isOpen, onClose, assistantPending, handleConfirm }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirmação</ModalHeader>
        <ModalBody>Deseja realmente gerar o tema por IA?</ModalBody>
        <ModalFooter>
          <Button variant="outline" mr={3} isDisabled={assistantPending} onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleConfirm} isLoading={assistantPending}>
            Confirmar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
