import { DetailsButton, GeneralCommentButton } from '@/components/EssayArea/EssayMenuButtons'
import type { CompleteEssayViewModel } from '@/services/types'
import { VStack } from '@chakra-ui/react'
import { GeneralComment } from './GeneralComment'

type EssayFloatingButtonProps = {
  essay?: CompleteEssayViewModel
  isEssayInProgress: boolean
}

export const EssayFloatingButtons = (props: EssayFloatingButtonProps) => {
  const { essay, isEssayInProgress } = props

  return (
    <VStack height="100%" p={4} position="absolute" right={0} top={0} zIndex={2} pointerEvents="none">
      <DetailsButton />
      {!isEssayInProgress && (
        <GeneralCommentButton>
          <GeneralComment essayId={essay?.id || ''} />
        </GeneralCommentButton>
      )}
    </VStack>
  )
}
