import './SignUpStep1.css'
import '../../../globals.css'
import { SelectedStep } from '@/components/FormSteps/SelectedStep.tsx'
import { UnselectedStep } from '@/components/FormSteps/UnselectedStep.tsx'
import IlustracaoLogin from '@/components/IlustracaoLogin'
import { InfoIcon } from '@chakra-ui/icons'
import { Box, FormErrorMessage, HStack, Hide, Icon, Image, Text, Tooltip, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import type { UserData } from 'src/pages/SignUpPage'
import * as yup from 'yup'

const validationSchema = yup.object({
  userType: yup.string().required(),
})

type CadastroStep1Props = {
  handlingNextStep: (userData?: UserData) => void
}
function SignUpStep1(props: CadastroStep1Props) {
  const { handlingNextStep } = props

  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) })

  const submit = (body: UserData) => {
    handlingNextStep(body)
  }

  return (
    <HStack className={'margin-bottom-conditional'} width={'100%'} height={'100%'} justifyContent={'center'}>
      <Hide below="md">
        <Box flex={1} height={'100%'} maxW={'750px'} display={{ base: 'none', md: 'block' }}>
          <IlustracaoLogin />
        </Box>
      </Hide>
      <Box flex={1} alignSelf={{ md: 'center', base: 'start' }} marginLeft={'16px'} marginTop={{ md: '32px', base: '0px' }}>
        <Box maxWidth={'700px'}>
          <Box alignContent={'start'}>
            <HStack flexDir={{ xl: 'row', md: 'column' }}>
              <Text className="publicsans-bold-absolute-zero-40px">Cadastre-se</Text>
              <Text className="span2-cadastro" hideBelow={'md'}>
                {' '}
                no Correção Online
              </Text>
            </HStack>
          </Box>
          <div className="content-3-cadastro">
            <Image hideBelow={'md'} className="line-cadastro" src="/img/line.svg" alt="Line" />
            <div className="step-01-cadastro">
              <SelectedStep number={1} />
              <p className="em-qual-perfil-voc-se-encaixa-cadastro publicsans-normal-nevada-24px">
                Em qual perfil você se encaixa?
              </p>
            </div>
            <Box paddingRight={'12px'} className="steps-cadastro">
              <Box marginLeft={{ base: '0px', md: '60px' }} className="cards-cadastro">
                <label style={{ width: '100%' }} htmlFor="Professor">
                  <HStack
                    padding={{ base: '8px', lg: '22px' }}
                    gap={{ base: '12px', lg: '22px' }}
                    cursor={'pointer'}
                    onClick={() => {
                      setValue('userType', 'Professor')
                      submit({ userType: 'Professor' })
                    }}
                    className={`card-user-type-cadastro ${watch('userType') === 'Professor' ? 'selected-card-user-type' : ''}`}>
                    <input
                      type="radio"
                      {...register('userType')}
                      value="Professor"
                      id="Professor"
                      checked={watch('userType') === 'Professor'}
                      className={'dot-1-cadastro '}
                    />
                    <div className="frame-2548-cadastro">
                      <Image className="avatar-photo-02-cadastro" src="/img/avatar-teacher.svg" alt="avatar - photo 02" />
                      <div className="frame-2551-cadastro">
                        <HStack
                          flexDir={{ md: 'row', base: 'column' }}
                          alignItems={'start'}
                          className={`${watch('userType') === 'Professor' ? 'publicsans-normal-absolute-zero-20px' : 'publicsans-normal-nevada-20px'}`}>
                          <Text>Professor Independente</Text>
                          <Box display="flex" alignItems="center">
                            <Tooltip
                              label={
                                <VStack align="start" spacing={1}>
                                  <Text>Este perfil é ideal para os professores de cursinhos ou professores autônomos.</Text>
                                </VStack>
                              }
                              aria-label="Uma dica">
                              <Icon as={InfoIcon} cursor="pointer" />
                            </Tooltip>
                          </Box>
                        </HStack>

                        <Box hideBelow={'md'} className="gesto-da-plataforma-cadastro publicsans-normal-shady-lady-16px">
                          Perfil exclusivo para professores autônomos.
                        </Box>
                      </div>
                    </div>
                  </HStack>
                </label>
                <label style={{ width: '100%' }} htmlFor="GestorEscolar">
                  <HStack
                    padding={{ base: '8px', lg: '22px' }}
                    gap={{ base: '12px', lg: '22px' }}
                    cursor={'pointer'}
                    onClick={() => {
                      setValue('userType', 'GestorEscolar')
                      submit({ userType: 'GestorEscolar' })
                    }}
                    className={`card-user-type-cadastro ${watch('userType') === 'GestorEscolar' ? 'selected-card-user-type' : ''}`}>
                    <input
                      type="radio"
                      {...register('userType')}
                      value="GestorEscolar"
                      id="GestorEscolar"
                      checked={watch('userType') === 'GestorEscolar'}
                      className={'dot-1-cadastro '}
                    />
                    <div className="content-4-cadastro">
                      <Image className="avatar-photo-02-cadastro" src="/img/avatar---photo-02-2.svg" alt="avatar - photo 02" />
                      <div className="txt-cadastro">
                        <HStack
                          flexDir={{ md: 'row', base: 'column' }}
                          alignItems={'start'}
                          className={`${watch('userType') === 'GestorEscolar' ? 'publicsans-normal-absolute-zero-20px' : 'publicsans-normal-nevada-20px'}`}>
                          <Text>Gestor Escolar</Text>
                          <Box display="flex" alignItems="center">
                            <Tooltip
                              label="É um perfil que lhe permite adicionar quantos professores você precisar."
                              aria-label="Uma dica">
                              <Icon as={InfoIcon} cursor="pointer" />
                            </Tooltip>
                          </Box>
                        </HStack>
                        <Box
                          hideBelow={'md'}
                          className="perfil-exclusivo-para-estudantes-cadastro publicsans-normal-shady-lady-16px">
                          Ideal para gestores escolares.
                        </Box>
                      </div>
                    </div>
                  </HStack>
                </label>
                <FormErrorMessage>{errors.userType?.message}</FormErrorMessage>
              </Box>
              <UnselectedStep number="2" text="Criar conta" />
              <UnselectedStep number="3" text="Informações Profissionais" />
            </Box>
          </div>
        </Box>
      </Box>
    </HStack>
  )
}

export default SignUpStep1
