import { type ChangeEvent, useState } from 'react'
import './MonitorArea.css'
import { AreaHeader } from '@/components/AreaHeader'
import SearchComponent from '@/components/SearchComponent'
import LayoutMenu from '@/layouts/LayoutMenu'
import { useGetApiV1TeacherSchoolSchoolId } from '@/services/teacher'
import type { TeacherGroupViewModel } from '@/services/types'
import { Link } from 'react-router-dom'
import SharedTablePagination from '../../components/SharedTablePagination'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import Page from '../Page'

function MonitorArea() {
  const [filterText, setFilterText] = useState('')
  const { loggedUser } = useLoggedUserStore()

  const getApiV1TeacherSchool = useGetApiV1TeacherSchoolSchoolId(loggedUser.schoolId)
  const allTeachers = getApiV1TeacherSchool.data?.data.message
  const allMonitors = allTeachers?.filter((item) => item.id !== loggedUser.user_id && item.role === 'Monitor')

  const monitors = allMonitors?.filter((item) => {
    return item?.name?.toLowerCase().includes(filterText.toLowerCase())
  })

  function handleMonitor(monitores) {
    return (
      <div className="atividade-txt-area-de-monitores">
        <div className="">
          <div className="x1-ano-area-de-monitores valign-text-middle-area-de-monitores publicsans-normal-black-14px">
            {monitores}
          </div>
        </div>
      </div>
    )
  }

  function handleTurmas(monitor: TeacherGroupViewModel) {
    return monitor?.groups?.map((item, key) => {
      return (
        <div className="frame-2580-area-de-monitores" key={key}>
          <div className="noite-area-de-monitores valign-text-middle-area-de-monitores publicsans-normal-black-12px">
            {item.name}
          </div>
        </div>
      )
    })
  }

  function geradorStatus(isBlocked?: boolean) {
    return (
      <div className="table-cell-container">
        <div className="frame-2581-1-area-de-monitores">
          <div className="ativa-area-de-monitores valign-text-middle-area-de-monitores publicsans-normal-black-12px">
            {isBlocked ? 'Bloqueado' : 'Ativo'}
          </div>
        </div>
      </div>
    )
  }

  function geradorSetas(monitorId?: string) {
    return (
      <Link to={`/detalhamento-monitor/${monitorId}`}>
        <div className="atividade-txt-4-area-de-monitores">
          <img className="ic-arrowright-area-de-monitores" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
        </div>
      </Link>
    )
  }

  function handleSearchInputChange(e: ChangeEvent<HTMLInputElement>) {
    const inputText = e.target.value
    setFilterText(inputText)
  }

  return (
    <Page authPage={true} allowedUserType={'teacher independentteacher'}>
      <LayoutMenu isLoading={getApiV1TeacherSchool?.isLoading}>
        <LayoutMenu.Content>
          <AreaHeader
            title="Área de Monitores"
            button={{
              isVisible: loggedUser.user_role === 'independentteacher',
              navigateTo: '/criar-monitor',
              label: 'Adicionar monitor',
            }}
          />

          <SearchComponent filterText={filterText} handleSearchInputChange={handleSearchInputChange} />

          <SharedTablePagination
            data={monitors || []}
            itemsPerPage={5}
            columns={[
              {
                title: 'Monitor',
                render: (itemData: TeacherGroupViewModel) => <div>{handleMonitor(itemData.name)}</div>,
              },
              {
                title: 'Status',
                render: (itemData: TeacherGroupViewModel) => <div>{geradorStatus(itemData.blocked)}</div>,
              },
              {
                title: 'Turmas vinculadas',
                render: (itemData: TeacherGroupViewModel) => (
                  <div className="table-cell-container">{handleTurmas(itemData)}</div>
                ),
              },
              {
                title: 'Detalhes',
                render: (itemData: TeacherGroupViewModel) => <div>{geradorSetas(itemData.id)}</div>,
              },
            ]}
          />
        </LayoutMenu.Content>
      </LayoutMenu>
    </Page>
  )
}

export default MonitorArea
