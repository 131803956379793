import {
  CommentButton,
  DetailsButton,
  GeneralCommentButton,
  MarkerButton,
  type MarkerProps,
} from '@/components/EssayArea/EssayMenuButtons'
import type { CompleteEssayViewModel } from '@/services/types'
import { Spacer, VStack } from '@chakra-ui/react'
import { useTouchDevice } from '../../components/EssayArea/EssayReviewArea.hooks'
import { GeneralComment } from './GeneralComment'

type EssayFloatingButtonProps = {
  essay: CompleteEssayViewModel
  isImage: boolean
  isAbleToReview: boolean
  marker: MarkerProps
}

export const EssayFloatingButtons = (props: EssayFloatingButtonProps) => {
  const { essay, isImage, marker, isAbleToReview } = props
  const isTouchDevice = useTouchDevice()
  const isTextReviewDesktop = !isImage && !isTouchDevice

  return (
    <VStack height="100%" p={4} position="absolute" right={0} top={0} zIndex={2} pointerEvents="none">
      <DetailsButton />
      <GeneralCommentButton>
        <GeneralComment essayId={essay?.id || ''} isAbleToReview={isAbleToReview} />
      </GeneralCommentButton>
      {isImage && isAbleToReview && <MarkerButton {...marker} />}
      <Spacer />
      {isAbleToReview && !isTextReviewDesktop && <CommentButton />}
    </VStack>
  )
}
