import { create } from 'zustand'

type Notification = {
  id: string
  description: string
  createdAt: string
  read: boolean
}

type NotificationStore = {
  notifications: Notification[]
  unreadCount: number
  setNotifications: (notifications: Notification[]) => void
  markAsRead: (notificationId: string) => void
}

const useNotificationStore = create<NotificationStore>((set) => ({
  notifications: [],
  unreadCount: 0,
  setNotifications: (notifications) =>
    set({
      notifications,
      unreadCount: notifications.filter((n) => !n.read).length,
    }),
  markAsRead: (notificationId) =>
    set((state) => {
      const updatedNotifications = state.notifications.map((n) => (n.id === notificationId ? { ...n, read: true } : n))
      return {
        notifications: updatedNotifications,
        unreadCount: updatedNotifications.filter((n) => !n.read).length,
      }
    }),
}))

export default useNotificationStore
