import {
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Image,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { listAllUnreadAlertsApi, readAlertApi } from '../../apis/alertApi/AlertApi'
import bellIcon from '../../assets/bell.svg'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import useNotificationStore from '../../store/useNotificationStore'
import { relativeDate } from '../../utils/DateSatinizerInput'

function NotificationsComponent() {
  const { notifications, unreadCount, setNotifications, markAsRead } = useNotificationStore()
  const { loggedUser } = useLoggedUserStore()
  const [hoverNotification, setHoverNotification] = useState<string>('')
  const [loading, setLoading] = useState(true)
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (loggedUser.user_role === 'owner') {
    return null
  }

  const fetchNotifications = async () => {
    try {
      setLoading(true)
      const notificationsResult = await listAllUnreadAlertsApi()
      setNotifications(notificationsResult)
    } catch (error) {
      console.error('Failed to fetch notifications:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (notifications.length === 0) {
      fetchNotifications()
    } else {
      setLoading(false)
    }
  }, [])

  const handleReadNotification = async (notificationId: string) => {
    try {
      await readAlertApi(notificationId)
      markAsRead(notificationId)
    } catch (error) {
      console.error('Failed to read notification:', error)
    }
  }

  return (
    <>
      <IconButton aria-label="notification-icon" isRound color="brand.200" variant="ghost" onClick={onOpen} mr={2}>
        <Box pos="relative">
          <Image src={bellIcon} alt="Vector" />
          {unreadCount > 0 && (
            <Box
              pos="absolute"
              minW="25px"
              right={-2}
              top={-1}
              p={1}
              bgColor="brandGreen.500"
              fontSize={unreadCount > 100 ? 'x-small' : 'small'}
              fontWeight="bold"
              borderRadius="full">
              {unreadCount}
            </Box>
          )}
        </Box>
      </IconButton>
      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent minW="300px">
          <DrawerCloseButton />
          <DrawerHeader>
            <Text color="gray.400" fontSize="sm">
              Notificações ({notifications?.length})
            </Text>
          </DrawerHeader>
          <Divider borderColor="gray.300" />
          <VStack p={2} gap={0} overflowY="auto">
            {loading ? (
              <Text>Aguarde um momento...</Text>
            ) : notifications.length === 0 ? (
              <Text fontSize="sm" color="gray.500" textAlign="center">
                Não há notificações novas
              </Text>
            ) : (
              notifications.map((notification) => (
                <Container
                  key={notification.id}
                  p={3}
                  borderRadius="lg"
                  _hover={{ bgColor: 'gray.100', cursor: 'default' }}
                  onMouseOver={() => setHoverNotification(notification.id)}
                  onMouseLeave={() => setHoverNotification('')}>
                  <HStack minHeight="35px">
                    <Text userSelect="none" fontSize="sm" color="gray.500">
                      há {relativeDate(notification.createdAt)}
                    </Text>
                    <Spacer />
                    {hoverNotification === notification.id && (
                      <Button
                        variant="ghost"
                        borderWidth={1}
                        borderColor="transparent"
                        _hover={{ borderColor: 'gray.300' }}
                        color="gray.500"
                        size="xs"
                        onClick={() => handleReadNotification(notification.id)}>
                        Marcar visualizada
                      </Button>
                    )}
                  </HStack>
                  <Text fontSize="sm" whiteSpace="break-spaces">
                    {notification.description.trim()}
                  </Text>
                </Container>
              ))
            )}
          </VStack>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default NotificationsComponent
