import { useNavigate } from 'react-router-dom'
import './SignUpByLink.css'
import { postApiV1StudentAcceptInvitation } from '@/services/student.ts'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Hide,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import type { AxiosResponse } from 'axios'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import HeaderCadastro from '../../components/HeaderCadastro'
import IlustracaoLogin from '../../components/IlustracaoLogin'
import FontsUtils from '../../theme/foundations/FontsUtils'
import fontUtils from '../../theme/foundations/FontsUtils'

const validationSchema = yup.object({
  email: yup.string().email().required(),
  name: yup.string().min(5).max(150).required(),
})

function SignUpByLink() {
  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get('token')

  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(validationSchema) })

  const submit = async (body: { email: string; name: string }) => {
    const response: AxiosResponse = await postApiV1StudentAcceptInvitation({
      studentEmail: body.email,
      studentName: body.name,
      token: token,
    })

    if (response.data.success) {
      toast.success('Cadastro concluído, confira a sua caixa de e-mail.')
      navigate('/login')
    }
  }

  return (
    <VStack width={'100%'} justifyContent={'center'} height={'100%'} gap={'0'}>
      <HeaderCadastro />

      <HStack width={'100%'} height={'100%'} justifyContent={'center'} gap={'32px'}>
        <Hide below="md">
          <Box flex={1} height={'100%'} maxW={'750px'} display={{ base: 'none', md: 'block' }}>
            <IlustracaoLogin />
          </Box>
        </Hide>
        <Box flex={1} p={'32px'} alignSelf={{ md: 'center', base: 'start' }}>
          <form onSubmit={handleSubmit(submit)}>
            <Box>
              <VStack gap={'16px'} w={'100%'} alignItems={'start'}>
                <Heading as={'h1'} fontSize={'4xl'} sx={fontUtils.fontPrimary}>
                  Cadastro
                </Heading>
                <Heading fontSize={'xl'} sx={fontUtils.fontGray}>
                  Você foi convidado através de um link
                </Heading>

                <Stack w={'100%'} marginTop={'32px'} alignItems={'start'} maxW={{ lg: '500px' }}>
                  <Stack width={'100%'} justifyContent={'center'}>
                    <FormControl width={'100%'} justifyContent={'center'} isInvalid={errors.email && !!errors.email.message}>
                      <FormLabel className="publicsans-bold-nevada-16px">E-mail</FormLabel>
                      <InputGroup width={'100%'}>
                        <InputLeftElement pointerEvents="none" height={'50px'} paddingLeft={'12px'}>
                          <Image src={'/img/ic-email.svg'} alt="ic-email" />
                        </InputLeftElement>
                        <Input
                          borderColor={'#d7e3fb'}
                          backgroundColor={'white'}
                          placeholder={'nome@dominio.com'}
                          type={'text'}
                          id={'email'}
                          w={'100%'}
                          height={'50px'}
                          paddingLeft={'52px'}
                          {...register('email')}
                        />
                      </InputGroup>

                      <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <Box w={'100%'} alignItems={'start'}>
                    <FormControl width={'100%'} justifyContent={'center'} isInvalid={errors.name && !!errors.name.message}>
                      <FormLabel className="publicsans-bold-nevada-16px">Nome</FormLabel>
                      <InputGroup width={'100%'}>
                        <InputLeftElement pointerEvents="none" height={'50px'} paddingLeft={'12px'}>
                          <Image src={'/img/profile-2851821-1.svg'} alt="ic-profile" />
                        </InputLeftElement>
                        <Input
                          borderColor={'#d7e3fb'}
                          backgroundColor={'white'}
                          placeholder="Seu Nome"
                          id={'name'}
                          w={'100%'}
                          height={'50px'}
                          paddingLeft={'52px'}
                          {...register('name')}
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                    </FormControl>
                  </Box>

                  <Button
                    type="submit"
                    isLoading={isSubmitting}
                    width={'100%'}
                    height={'48px'}
                    variant="primary"
                    fontSize={'m'}
                    mt={'16px'}
                    sx={FontsUtils.publicSansBoldWhite}>
                    Se cadastrar
                  </Button>
                </Stack>
              </VStack>
            </Box>
          </form>
        </Box>
      </HStack>
    </VStack>
  )
}

export default SignUpByLink
