import { useKeyboardEvents } from '@/components/EssayArea/EssayReviewArea.hooks'
import { Box, Kbd, Text, keyframes } from '@chakra-ui/react'
import { useTheme } from '@chakra-ui/react'

export const EssayImageShortcut = () => {
  const { isMac } = useKeyboardEvents()
  const theme = useTheme()

  const pulse = keyframes`
    0% { box-shadow: 0 0 0 0 ${theme.colors.brand[100]}66 }
    70% { box-shadow: 0 0 100px 100px ${theme.colors.brand[100]}00 }
    100% { box-shadow: 0 0 0 0 ${theme.colors.brand[100]}00 }
  `

  const slideUp = keyframes`
    from { transform: translateY(100%) translateX(-50%) }
    to { transform: translateY(0) translateX(-50%) }
  `

  const pulseAnimation = `${pulse} 1.5s ease-in-out 8`
  const slideUpAnimation = `${slideUp} 1s ease-out forwards`

  return (
    <Box
      position="absolute"
      bg="white"
      bottom={6}
      py={3}
      px={5}
      borderRadius="full"
      borderWidth={1}
      borderStyle="solid"
      borderColor={'gray.300'}
      left="50%"
      zIndex={1}
      display={{ base: 'none', lg: 'block' }}
      animation={`${slideUpAnimation}, ${pulseAnimation}`}>
      <Text fontWeight="bold" color="gray.500" align="center">
        Segure a tecla
        <Kbd mx={2} borderStyle="solid" fontSize="lg">
          {isMac ? 'CMD⌘' : 'ALT'}
        </Kbd>
        + clique e arraste para comentar
      </Text>
    </Box>
  )
}
