/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-11-30 17:50:40 UTC)
 * OpenAPI spec version: v1
 */
import type { SchoolAddressViewModel } from './schoolAddressViewModel';
import type { BasicGroupViewModel } from './basicGroupViewModel';

export interface SchoolViewModel {
  address?: SchoolAddressViewModel;
  /** @nullable */
  addressId?: string | null;
  admId?: string;
  deleted?: boolean;
  /** @nullable */
  document?: string | null;
  essayAssistantBalance?: number;
  essayBalance?: number;
  /** @nullable */
  groups?: BasicGroupViewModel[] | null;
  id?: string;
  /** @nullable */
  name?: string | null;
  themeAssistantBalance?: number;
  /** @nullable */
  thumbnail?: string | null;
  totalGroups?: number;
  totalStudents?: number;
  totalTeachers?: number;
}
