import './ResetPassword.css'
import '../../../globals.css'
import { usePostApiV1AccountForgotPassword } from '@/services/auth.ts'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Hide,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import HeaderCadastro from '../../components/HeaderCadastro'
import IlustracaoLogin from '../../components/IlustracaoLogin'
import fontUtils from '../../theme/foundations/FontsUtils'
import FontsUtils from '../../theme/foundations/FontsUtils'

const validationSchema = yup.object({
  email: yup.string().email().required(),
})

function ResetPassword() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) })

  const postApiV1AccountForgotPassword = usePostApiV1AccountForgotPassword({
    mutation: {
      onSuccess: () => toast.success('Senha reiniciada com sucesso'),
      onError: () => toast.error('Erro ao reiniciar senha'),
    },
  })

  const submit = async (body: { email: string }) => {
    postApiV1AccountForgotPassword.mutate({ data: body })
  }

  return (
    <VStack width={'100%'} justifyContent={'center'} height={'100%'} gap={'0'}>
      <HeaderCadastro />

      <HStack className={'height-fixed'} width={'100%'} height={'100%'} justifyContent={'center'} gap={'32px'}>
        <Hide below="md">
          <Box flex={1} height={'100%'} maxW={'750px'} display={{ base: 'none', md: 'block' }}>
            <IlustracaoLogin />
          </Box>
        </Hide>
        <Box className={'form-scroll'} flex={1} p={'32px'} alignSelf={{ md: 'center', base: 'start' }}>
          <form className={'login-body'} onSubmit={handleSubmit(submit)} noValidate>
            <VStack gap={'16px'} w={'100%'} alignItems={'start'} maxW={'500px'}>
              <Heading as={'h1'} fontSize={'4xl'} sx={fontUtils.fontPrimary}>
                Esqueceu a senha?
              </Heading>
              <Heading fontSize={'xl'} sx={fontUtils.fontGray}>
                Insira um endereço de email associado a sua conta, enviaremos um link de redefinição.
              </Heading>

              <Stack w={'100%'} marginTop={'32px'} gap={'32px'} alignItems={'start'}>
                <FormControl width={'100%'} justifyContent={'center'} isInvalid={errors.email && !!errors.email.message}>
                  <FormLabel className="publicsans-bold-nevada-16px">E-mail</FormLabel>
                  <InputGroup width={'100%'}>
                    <InputLeftElement pointerEvents="none" height={'50px'} paddingLeft={'12px'}>
                      <Image src={'/img/ic-email.svg'} alt="ic-email" />
                    </InputLeftElement>
                    <Input
                      borderColor={'#d7e3fb'}
                      backgroundColor={'white'}
                      placeholder={'nome@dominio.com'}
                      type={'text'}
                      id={'email'}
                      w={'100%'}
                      height={'50px'}
                      paddingLeft={'52px'}
                      {...register('email')}
                    />
                  </InputGroup>

                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
              </Stack>

              <VStack width={'100%'} justifyContent={'center'} mt={'32px'}>
                <Button
                  type={'submit'}
                  isLoading={postApiV1AccountForgotPassword.isPending}
                  width={'100%'}
                  height={'48px'}
                  variant="primary"
                  fontSize={'m'}
                  whiteSpace={'pre-wrap'}
                  sx={FontsUtils.publicSansBoldWhite}>
                  {' '}
                  Enviar link de redefinição{' '}
                </Button>

                <Link to={'/login'} style={{ width: '100%' }}>
                  <Button
                    isDisabled={postApiV1AccountForgotPassword.isPending}
                    width={'100%'}
                    height={'48px'}
                    variant={'outline'}
                    fontSize={'m'}
                    sx={FontsUtils.publicSansBoldAbsoluteZero}>
                    Voltar
                  </Button>
                </Link>
              </VStack>
            </VStack>
          </form>
        </Box>
      </HStack>
    </VStack>
  )
}

export default ResetPassword
