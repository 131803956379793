import {
  useDeleteApiV1AssistantThemeAssistantId,
  usePostApiV1AssistantThemeAssistant,
  usePutApiV1AssistantThemeAssistant,
} from '@/services/assistant.ts'
import type { ThemeAssistantPromptViewModel } from '@/services/types'
import { Box, Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, Textarea, VStack } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import type { AxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'

interface PromptModalBodyProps {
  prompt: ThemeAssistantPromptViewModel | null
  onClose: () => void
  fetchPrompts: () => void
}

interface PromptFormValues {
  title: string
  model: string
  systemContent: string
  mainContent: string
}

const validationSchema = yup.object().shape({
  title: yup.string().min(10).max(255).required(),
  systemContent: yup.string().min(10).max(500).required(),
  model: yup.string().default(''),
  mainContent: yup.string().min(15).max(200).required(),
})

export function ThemePromptModalBody({ prompt, onClose, fetchPrompts }: PromptModalBodyProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PromptFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: prompt?.title || '',
      systemContent: prompt?.systemContent || '',
      mainContent: prompt?.content || '',
    },
  })

  const { mutateAsync: createPrompt, isPending: isCreationPending } = usePostApiV1AssistantThemeAssistant<AxiosError>({
    mutation: {
      onSuccess: () => {
        fetchPrompts()
        toast.success('Prompt de tema criado com sucesso!')
        onClose()
      },
    },
  })

  const { mutateAsync: updatePrompt, isPending: isUpdatePending } = usePutApiV1AssistantThemeAssistant<AxiosError>({
    mutation: {
      onSuccess: () => {
        fetchPrompts()
        toast.success('Prompt de tema alterado com sucesso!')
        onClose()
      },
    },
  })

  const { mutateAsync: deletePrompt, isPending: isDeletePending } = useDeleteApiV1AssistantThemeAssistantId<AxiosError>({
    mutation: {
      onSuccess: () => {
        fetchPrompts()
        toast.success('Prompt de tema removido com sucesso!')
        onClose()
      },
    },
  })

  const onSubmit = async (data: PromptFormValues) => {
    const newPromptData = {
      title: data.title,
      systemContent: data.systemContent,
      content: data.mainContent,
      isDefault: false,
      id: prompt?.id,
    }

    try {
      prompt ? await updatePrompt({ data: newPromptData }) : await createPrompt({ data: newPromptData })
    } catch (error) {
      console.error('Erro ao criar prompt de tema', error)
    }
  }

  function handleDeletePrompt() {
    if (!prompt?.id) return
    deletePrompt({ id: prompt?.id })
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <VStack gap={5}>
        <FormControl isInvalid={!!errors.title}>
          <FormLabel>Título</FormLabel>
          <Input {...register('title')} />
          <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.systemContent}>
          <FormLabel>Contexto</FormLabel>
          <Textarea {...register('systemContent')} />
          <FormErrorMessage>{errors.systemContent?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.mainContent}>
          <FormLabel>Prompt Principal</FormLabel>
          <Textarea minHeight="300px" {...register('mainContent')} />
          <FormErrorMessage>{errors.mainContent?.message}</FormErrorMessage>
        </FormControl>
      </VStack>
      {prompt ? (
        <HStack pt={5} justifyContent={'space-between'}>
          <Button float="right" color={'red'} onClick={handleDeletePrompt} isLoading={isDeletePending}>
            Apagar prompt
          </Button>
          <Button float="right" variant="primary" type="submit" isLoading={isUpdatePending} isDisabled={isDeletePending}>
            Atualizar prompt
          </Button>
        </HStack>
      ) : (
        <Button float="right" variant="primary" type="submit" mt={4} isLoading={isCreationPending}>
          Criar prompt
        </Button>
      )}
    </Box>
  )
}
