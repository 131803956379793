/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-11-30 17:50:40 UTC)
 * OpenAPI spec version: v1
 */

export * from './activityForStudentViewModel';
export * from './activityForStudentViewModelListResultViewModel';
export * from './activityType';
export * from './activityViewModel';
export * from './activityViewModelResultViewModel';
export * from './activityWithStudentViewModel';
export * from './activityWithStudentViewModelResultViewModel';
export * from './activityWithTotalsViewModel';
export * from './activityWithTotalsViewModelListResultViewModel';
export * from './addTeacherToSchoolAndGroupsViewModel';
export * from './admUpdateStudentViewModel';
export * from './admUpdateTeacherViewModel';
export * from './admViewModel';
export * from './alertViewModel';
export * from './alertViewModelListResultViewModel';
export * from './alertViewModelResultViewModel';
export * from './apiKeyViewModel';
export * from './assistantPromptViewModel';
export * from './assistantPromptViewModelListResultViewModel';
export * from './assistantReactionsViewModel';
export * from './assistantReactionsViewModelListResultViewModel';
export * from './assistantTipViewModel';
export * from './assistantTipViewModelResultViewModel';
export * from './assistantUsageBasicInfoViewModel';
export * from './assistantUsageBasicInfoViewModelListResultViewModel';
export * from './assistantUsageContentViewModel';
export * from './assistantUsageContentViewModelListResultViewModel';
export * from './assistantUsageReaction';
export * from './assistantUsageReactionViewModel';
export * from './basicEssayCompetencyGrade';
export * from './basicGroupViewModel';
export * from './basicGroupViewModelListResultViewModel';
export * from './basicStudentViewModel';
export * from './basicThemeViewModel';
export * from './basicThemeViewModelResultViewModel';
export * from './blockUserViewModel';
export * from './booleanResultViewModel';
export * from './changeEssayTeacherViewModel';
export * from './changePasswordViewModel';
export * from './commentViewModel';
export * from './commentViewModelListResultViewModel';
export * from './competencyViewModel';
export * from './competencyViewModelResultViewModel';
export * from './completeEssayViewModel';
export * from './completeEssayViewModelResultViewModel';
export * from './confirmEmailViewModel';
export * from './contactCompleteViewModel';
export * from './contactCompleteViewModelResultViewModel';
export * from './contactInteractionResponseViewModel';
export * from './contactResponseViewModel';
export * from './contactResponseViewModelResultViewModel';
export * from './contactViewModel';
export * from './countryViewModel';
export * from './countryViewModelListResultViewModel';
export * from './deleteApiV1SharedAddressSchoolIdParams';
export * from './deleteApiV1TeacherIndependentTeacherIdParams';
export * from './details';
export * from './essayBalanceTransactionSource';
export * from './essayBalanceTransactionType';
export * from './essayCommentType';
export * from './essayCompetencyViewModel';
export * from './essayGroupViewModel';
export * from './essayGroupViewModelListResultViewModel';
export * from './essayPriceViewModel';
export * from './essayPriceViewModelListResultViewModel';
export * from './essayStudentViewModel';
export * from './essayStudentViewModelResultViewModel';
export * from './essayTeacherViewModel';
export * from './essayTeacherViewModelListResultViewModel';
export * from './essayViewModel';
export * from './essayWithStudentViewModel';
export * from './essaysPerGroupsViewModel';
export * from './experimentThemeAssistantPromptResponseViewModel';
export * from './experimentThemeAssistantPromptViewModel';
export * from './forgotPasswordViewModel';
export * from './getApiV1AccountValidateTokenParams';
export * from './getApiV1AssistantAssistantAnalysisParams';
export * from './getApiV1AssistantListAssistantUsagesEssayParams';
export * from './getApiV1AssistantListAssistantUsagesSchoolParams';
export * from './getApiV1AssistantListAssistantUsagesSchoolTeacherParams';
export * from './getApiV1EssayGroupGroupIdParams';
export * from './getApiV1EssayStudentStudentIdParams';
export * from './getApiV1EssayTeacherTeacherIdParams';
export * from './getApiV1MonetizationEssayCheckPurchaseTokenParams';
export * from './getApiV1MonetizationGetEssayPricePerDateDateParams';
export * from './getApiV1SchoolSchoolAdmParams';
export * from './groupStudentViewModel';
export * from './groupTeacherViewModel';
export * from './groupViewModel';
export * from './groupViewModelResultViewModel';
export * from './guidResultViewModel';
export * from './idListViewModel';
export * from './institutionViewModel';
export * from './institutionViewModelListResultViewModel';
export * from './institutionViewModelResultViewModel';
export * from './keyValueDoubleViewModel';
export * from './keyValueViewModel';
export * from './keyValueViewModelListResultViewModel';
export * from './listGroups';
export * from './listOfEssayCompetenciesViewModel';
export * from './listOfEssayCompetenciesViewModelResultViewModel';
export * from './loginUserViewModel';
export * from './newActivityViewModel';
export * from './newAdmAnonymousViewModel';
export * from './newAdmViewModel';
export * from './newAlertViewModel';
export * from './newAssistantPromptViewModel';
export * from './newAssistantUsageViewModel';
export * from './newBatchStudentsViewModel';
export * from './newCommentViewModel';
export * from './newCompetencyViewModel';
export * from './newCountryViewModel';
export * from './newEssayPriceViewModel';
export * from './newEssayViewModel';
export * from './newIndependentTeacherViewModel';
export * from './newInstitutionViewModel';
export * from './newInteractionViewModel';
export * from './newMonitorViewModel';
export * from './newSchoolGroupViewModel';
export * from './newSchoolViewModel';
export * from './newStudentFromIndependentTeacherViewModel';
export * from './newStudentViewModel';
export * from './newSupportMaterialViewModel';
export * from './newTeacherViewModel';
export * from './newThemeAssistantPromptUsageViewModel';
export * from './newThemeAssistantPromptViewModel';
export * from './newThemeViewModel';
export * from './parentCommentViewModel';
export * from './postApiV1AccountSetLanguageParams';
export * from './problemDetails';
export * from './purchaseEssayViewModel';
export * from './removeContactInteractionViewModel';
export * from './resendEmailConfirmationViewModel';
export * from './returnEssayViewModel';
export * from './schoolAddressViewModel';
export * from './schoolAdmInstitutionViewModel';
export * from './schoolAnalyticsVieWModel';
export * from './schoolAnalyticsVieWModelListResultViewModel';
export * from './schoolEssayAdmViewModel';
export * from './schoolEssayAdmViewModelListResultViewModel';
export * from './schoolEssayTransactionViewModel';
export * from './schoolEssayTransactionViewModelListResultViewModel';
export * from './schoolStudentViewModel';
export * from './schoolTeacherViewModel';
export * from './schoolViewModel';
export * from './schoolViewModelListResultViewModel';
export * from './schoolViewModelResultViewModel';
export * from './simpleTeacherViewModel';
export * from './simpleTeacherViewModelListResultViewModel';
export * from './stringListResultViewModel';
export * from './stringResultViewModel';
export * from './studentEssayDetailsViewModel';
export * from './studentInviteViewModel';
export * from './studentListViewModel';
export * from './studentListViewModelListResultViewModel';
export * from './studentNameViewModel';
export * from './studentNameViewModelResultViewModel';
export * from './studentViewModel';
export * from './studentViewModelListResultViewModel';
export * from './studentViewModelResultViewModel';
export * from './supportMaterialViewModel';
export * from './supportMaterialViewModelIEnumerableResultViewModel';
export * from './supportMaterialViewModelResultViewModel';
export * from './teacherGroupViewModel';
export * from './teacherGroupViewModelListResultViewModel';
export * from './teacherSchoolViewModel';
export * from './teacherSchoolViewModelListResultViewModel';
export * from './teacherSchoolViewModelResultViewModel';
export * from './themeAssistantPromptReactionViewModel';
export * from './themeAssistantPromptUsageResponseViewModel';
export * from './themeAssistantPromptViewModel';
export * from './themeAssistantPromptViewModelIEnumerableResultViewModel';
export * from './themeViewModel';
export * from './themeViewModelListResultViewModel';
export * from './updateActivityViewModel';
export * from './updateAdmViewModel';
export * from './updateAssistantPromptViewModel';
export * from './updateCommentCompetency';
export * from './updateCompetencyViewModel';
export * from './updateCountryViewModel';
export * from './updateEssayDraftViewModel';
export * from './updateEssayPriceValueViewModel';
export * from './updateIndependentTeacherViewModel';
export * from './updateInstitutionViewModel';
export * from './updateSchoolGroupViewModel';
export * from './updateSchoolThumbnailViewModel';
export * from './updateSchoolViewModel';
export * from './updateStudentViewModel';
export * from './updateSupportMaterialViewModel';
export * from './updateTeacherViewModel';
export * from './updateThemeAssistantPromptViewModel';
export * from './updateThemeImageViewModel';
export * from './updateThemeViewModel';
export * from './userGroupsViewModel';
export * from './userRole';
export * from './userViewModel';
export * from './userViewModelListResultViewModel';