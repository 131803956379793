import type { CommentViewModel, EssayCompetencyViewModel } from '@/services/types'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  type DrawerProps,
  HStack,
  Heading,
  IconButton,
  Img,
  Spinner,
  Text,
  Tooltip,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react'
import { formatRelative } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { type FormEvent, useEffect, useRef, useState } from 'react'
import { IoMdTrash } from 'react-icons/io'
import { IoArrowUpOutline } from 'react-icons/io5'
import TextareaAutosize from 'react-textarea-autosize'
import useLoggedUserStore from '../../store/useLoggedUserStore'

import './CommentDrawer.css'
import { useGetApiV1EssayEssayIdComments } from '@/services/essay'

type CommentDrawerProps = {
  essayId: string
  isOpen: boolean
  onCloseDrawer: () => void
  competencies: EssayCompetencyViewModel[]
  selectedCommentId?: string | null
  isViewOnlyMode: boolean
  action: {
    isSaving: boolean
    isUpdating?: boolean
    isDeleting: boolean
    save: (comment?: string, competencyId?: string, parentId?: string | null) => void
    update?: (commentId?: string, competencyId?: string) => void
    delete: (commentId: string) => void
  }
}
export const CommentDrawer = ({
  isOpen,
  onCloseDrawer,
  competencies,
  selectedCommentId,
  isViewOnlyMode,
  action,
  essayId,
}: CommentDrawerProps) => {
  const [selectedCompetency, setSelectedCompetency] = useState<EssayCompetencyViewModel>()
  const [newComment, setNewComment] = useState<string>()
  const { loggedUser } = useLoggedUserStore()
  const getApiV1EssayComments = useGetApiV1EssayEssayIdComments(essayId, { query: { enabled: false } })
  const allComments = getApiV1EssayComments.data?.data.message
  const initialFocusRef = useRef<HTMLTextAreaElement>(null)

  const comments = mapComments(allComments, selectedCommentId)

  const mainComment = comments?.[comments.length - 1]
  const isNewCommentEmpty = !newComment?.trim().length

  useEffect(() => {
    initialFocusRef?.current?.focus()
  }, [isOpen])

  useEffect(() => {
    if (isOpen && essayId) getApiV1EssayComments.refetch()
  }, [isOpen, essayId])

  useEffect(() => {
    const currentSelectedCompetency = competencies.find((competency) => competency.id === mainComment?.competencyId)
    setSelectedCompetency(currentSelectedCompetency || competencies[0])
  }, [mainComment?.id, mainComment?.competencyId])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    action.save(newComment?.trim(), selectedCompetency?.id, selectedCommentId)
    setNewComment('')
  }

  const handleSelectedCompentency = (competency: EssayCompetencyViewModel) => {
    setSelectedCompetency(competency)
    if (!selectedCommentId || competency.id === selectedCompetency?.id) return
    action.update?.(selectedCommentId, competency.id)
  }

  const isReviewerInReview = action.update && !isViewOnlyMode
  const isStudentOrFinalized = !action.update || isViewOnlyMode
  const isMaxHeight = useBreakpointValue({ base: '150px', sm: '100%' })
  const drawerPosition = useBreakpointValue<DrawerProps['placement']>({ base: 'bottom', sm: 'right' })
  return (
    <Drawer isOpen={isOpen} placement={drawerPosition} onClose={onCloseDrawer}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <Heading fontSize="sm" color="gray.500">
            Competência {action.isUpdating && <Spinner size="xs" />}
          </Heading>
          <HStack mt={2}>
            {isReviewerInReview &&
              competencies.map((competency) => (
                <Tooltip key={competency.id} label={competency.description}>
                  <Button
                    variant={selectedCompetency?.id === competency.id ? 'primary' : 'outline'}
                    _hover={selectedCompetency?.id === competency.id ? { cursor: 'default' } : undefined}
                    borderColor="gray.200"
                    onClick={() => handleSelectedCompentency(competency)}>
                    {competency.name}
                  </Button>
                </Tooltip>
              ))}
            {isStudentOrFinalized && (
              <Button variant="primary" _hover={{ cursor: 'default' }} borderColor="gray.200">
                {selectedCompetency?.name}
              </Button>
            )}
          </HStack>
        </DrawerHeader>
        <DrawerBody
          // Fix scroll issues on desktop and mobile
          onWheel={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchMove={(e) => e.stopPropagation()}
          p={2}
          display="flex"
          flexDirection="column-reverse"
          overflowY="auto"
          gap={1}
          height="100%"
          maxH={isMaxHeight}
          minH="250px"
          overscrollBehavior="contain"
          bgColor="gray.50">
          {comments.length === 0 && (
            <VStack padding={10} height="100%" justifyContent="center">
              <Img src="/img/no-comment-illustration.svg" w={'150px'} />
              <Text align="center" color="gray.400" pt={3}>
                Envie o primeiro comentário
              </Text>
            </VStack>
          )}
          {comments.map((comment) => {
            const isOwner = comment.user?.id === loggedUser.user_id
            const isFirstComment = comment.id === selectedCommentId
            const isCommentDeletable = isOwner && !isViewOnlyMode && (isFirstComment || comment.parent)
            return (
              <Box
                className="show-more"
                key={comment.id}
                display="flex"
                position="relative"
                justifyContent={isOwner ? 'end' : 'start'}>
                {isCommentDeletable && (
                  <IconButton
                    position="absolute"
                    bgColor="gray.300"
                    size="xs"
                    aria-label="Deletar mensagem"
                    m={1}
                    visibility="hidden"
                    icon={<IoMdTrash />}
                    isLoading={action.isDeleting}
                    onClick={() => comment.id && action.delete(comment.id)}
                  />
                )}
                <Box
                  pointerEvents="none"
                  bgColor={isOwner ? 'gray.100' : 'blue.100'}
                  maxW="85%"
                  px={3}
                  py={2}
                  borderRadius="lg">
                  <HStack spacing={4}>
                    <Text
                      flex={1}
                      fontSize={'13px'}
                      fontWeight={'600'}
                      color="gray.500"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap">
                      {comment?.user?.name}
                    </Text>
                    <Text fontSize={'12px'} fontWeight={'300'} color="gray.500" textAlign="right" whiteSpace="nowrap">
                      {formatRelative(new Date(comment.createdAt || ''), new Date(), { locale: ptBR })}
                    </Text>
                  </HStack>
                  <Text maxWidth="100%" whiteSpace="pre-line">
                    {comment.comment}
                  </Text>
                </Box>
              </Box>
            )
          })}
        </DrawerBody>
        {!isViewOnlyMode && (
          <DrawerFooter>
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <HStack p={0} borderRadius="5px" alignItems="end">
                <TextareaAutosize
                  ref={initialFocusRef}
                  style={{ padding: '0 0 3px', lineHeight: '23px', flex: 1, outline: 'none', resize: 'none' }}
                  minRows={1}
                  maxRows={5}
                  autoFocus={true}
                  value={newComment}
                  disabled={isViewOnlyMode}
                  placeholder="Escreva um comentário"
                  onChange={(e) => {
                    setNewComment(e.target.value || '')
                  }}
                  onKeyDown={(e) => {
                    if (isNewCommentEmpty) return
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault()
                      handleSubmit(e)
                    }
                  }}
                />
                <IconButton
                  aria-label="Enviar"
                  type="submit"
                  size="sm"
                  p={0}
                  isRound={true}
                  variant="primary"
                  icon={<IoArrowUpOutline />}
                  isLoading={action.isSaving}
                  isDisabled={isNewCommentEmpty}
                />
              </HStack>
            </form>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}

const mapComments = (allComments?: CommentViewModel[] | null, selectedCommentId?: string | null) => {
  if (selectedCommentId) {
    const comments = allComments?.filter(
      (comment) => comment.id === selectedCommentId || comment.parent?.messageId === selectedCommentId,
    )
    return comments?.reverse() || []
  }
  return []
}
