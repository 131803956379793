/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-11-30 17:50:40 UTC)
 * OpenAPI spec version: v1
 */
import type { Details } from './details';
import type { CompetencyViewModel } from './competencyViewModel';

export interface CompetencyViewModelResultViewModel {
  details?: Details;
  message?: CompetencyViewModel;
  success?: boolean;
}
