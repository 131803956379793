/**
 * Generated by orval v7.3.0 🍺
 * Do not edit manually.
 * API - Correção Online
 * API do projeto Correção Online (Deploy 2024-11-30 17:50:40 UTC)
 * OpenAPI spec version: v1
 */

export type EssayBalanceTransactionType = typeof EssayBalanceTransactionType[keyof typeof EssayBalanceTransactionType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EssayBalanceTransactionType = {
  NUMBER_0: 0,
  NUMBER_1: 1,
} as const;
