import { useEffect, useState } from 'react'
import './StudentDetails.css'
import { useAddToHistory } from '@/utils/useNavigationHooks'
import {
  Box,
  Button,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { MultiSelect } from 'chakra-multiselect'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getStudentById } from '../../apis/studentApi/studentApi'
import GetModalEditStudent from '../../components/GetModalEditStudent'
import Headbar from '../../components/Headbar'
import NoContentContainer from '../../components/NoContentContainer'
import SearchComponent from '../../components/SearchComponent'
import SharedTablePagination from '../../components/SharedTablePagination'
import { getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays } from '../../services/activity'
import { deleteApiV1StudentRemoveFromGroups } from '../../services/student'
import useLoggedUserStore from '../../store/useLoggedUserStore'
import sanitizedDate from '../../utils/DateSatinizerInput'
import { RoleTypes } from '../../utils/RoleTypes'
import { essayStatus, returnEssayStatusClassName } from '../../utils/essayStatus'
import { HandleSubmittedDate } from '../../utils/sharedTableColumnHandlers'
import Page from '../Page'

function StudentDetails() {
  const navigate = useNavigate()
  const { alunoID } = useParams()
  const { loggedUser } = useLoggedUserStore()

  const [studentData, setStudentData] = useState({})
  const [activities, setActivities] = useState([])
  const [essays, setEssays] = useState([])
  const [activitiesData, setActivitiesData] = useState([])
  const [essaysData, setEssaysData] = useState([])
  const [loading, setLoading] = useState(true)
  const [activitiesFilterText, setActivitiesFilterText] = useState('')
  const [essaysFilterText, setEssaysFilterText] = useState('')
  const [selectedTab, setSelectedTab] = useState('redacoes')
  const [studentGroups, setStudentGroups] = useState([])
  const [groupsToRemoveFrom, setGroupsToRemoveFrom] = useState([])
  const [userCanRemoveStudent] = useState(loggedUser.user_role !== 'adm' && loggedUser.user_role !== 'independentteacher')

  const modalDisclosure = useDisclosure()
  const popoverDisclosure = useDisclosure()

  const fetchInitialData = () => {
    return new Promise((resolve) => {
      Promise.all([
        getStudentById(alunoID),
        getApiV1ActivityStudentStudentIdSchoolSchoolIdDaysDays(alunoID, loggedUser.schoolId, 0),
      ]).then(([studentResult, activitiesResult]) => {
        resolve({ studentResult, activitiesResult: activitiesResult.data.message })
      })
    })
  }

  useAddToHistory()

  useEffect(() => {
    fetchInitialData().then(({ studentResult, activitiesResult }) => {
      setStudentData(studentResult || {})
      setEssaysData(studentResult !== null ? studentResult.essays : [])
      setActivitiesData(activitiesResult)
      setStudentGroups(
        studentResult.schools[0].groups.map((group, key) => {
          return { value: group.name, label: group.name, id: group.id, key: key }
        }),
      )
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (activitiesData) {
      setActivities(
        activitiesData?.filter((item) => {
          const result =
            item.subject.toLowerCase().includes(activitiesFilterText.toLowerCase()) ||
            item.theme.name.toLowerCase().includes(activitiesFilterText.toLowerCase()) ||
            (item.deadline && sanitizedDate(item.deadline).includes(activitiesFilterText)) ||
            (item.validFrom && sanitizedDate(item.validFrom).includes(activitiesFilterText))

          return result
        }),
      )
    }
  }, [activitiesFilterText, activitiesData])

  useEffect(() => {
    if (essaysData) {
      setEssays(
        essaysData.filter((item) => {
          const result =
            item.activitySubject.toLowerCase().includes(essaysFilterText.toLowerCase()) ||
            (item.dateSubmitted && sanitizedDate(item.dateSubmitted).includes(essaysFilterText)) ||
            essayStatus[item.status].label.toLowerCase().includes(essaysFilterText.toLowerCase())

          return result
        }),
      )
    }
  }, [essaysFilterText, essaysData])

  function handleEssaysSearchInputChange(e) {
    setEssaysFilterText(e.target.value)
  }

  function handleActivitiesSearchInputChange(e) {
    setActivitiesFilterText(e.target.value)
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  function HandleStatusInput({ status }) {
    const backgroundColor = returnEssayStatusClassName(status)
    return (
      <div className={'atividade-txt-detalhamento-aluno publicsans-normal-black-12px'}>
        <div className={backgroundColor}>{essayStatus[status]?.label}</div>
      </div>
    )
  }

  function HandleGroups() {
    if (studentData.schools) {
      return studentData.schools[0].groups.map((group, key) => {
        return <Frame25802 key={key}>{group.name}</Frame25802>
      })
    }
  }

  function StudentDetails(props) {
    const { title, imgPath, details } = props

    return (
      <Box gap={'8px'}>
        <div className="publicsans-normal-nevada-16px">{title}</div>
        <Box pt={1} className="data-detalhamento-aluno">
          <img className="student-details-icon-style" src={imgPath} alt="message-2851808" />
          <div className="publicsans-normal-nevada-14px">{details}</div>
        </Box>
      </Box>
    )
  }

  function HandleGroup({ groupName }) {
    return (
      <div className={'table-cell-container publicsans-normal-black-12px'}>
        <Text> {groupName} </Text>
      </div>
    )
  }

  function HandleDetails(essay) {
    return (
      <div className={'teste publicsans-normal-black-12px'}>
        {
          <Link to={`/redacao-professor/${essay.essayId}`}>
            <div className="atividade-txt-4-area-de-turmas">
              <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
            </div>
          </Link>
        }
      </div>
    )
  }

  function HandleActivityDetails({ activityId }) {
    return (
      <div className={'teste publicsans-normal-black-12px'}>
        {
          <Link to={`/detalhamento-atividade/${activityId}`}>
            <div className="atividade-txt-4-area-de-turmas">
              <img className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
            </div>
          </Link>
        }
      </div>
    )
  }

  return (
    <div className="detalhamento-aluno screen-detalhamento-aluno">
      <VStack width={'100%'} alignSelf={'start'} gap={0}>
        <Page authPage={true} allowedUserType={'teacher monitor independentteacher adm'} />
        <Headbar />
        <HStack
          width={'90%'}
          flexDir={{ md: 'row', base: 'column' }}
          gap={'16px'}
          justifyContent={'space-between'}
          className={'content-max-size'}>
          <VStack gap={5} pb={5} className={'detalhamento-aluno-body'}>
            <Box className="tema-detalhamento-aluno" w={'100%'}>
              <VStack pt={5} pb={5} width={'100%'}>
                <HStack width={'100%'} justify={'space-between'}>
                  <VStack flex={1} width={'100%'} alignItems={'flex-start'}>
                    <Text className="publicsans-bold-nevada-10px">ALUNO(A)</Text>

                    <Text wordBreak={'break-all'} className="publicsans-normal-absolute-zero-24px">
                      {loading ? '' : studentData.name}
                    </Text>
                    <Text
                      py={'8px'}
                      px={'16px'}
                      border={'1px solid'}
                      borderRadius={'8px'}
                      backgroundColor={'brand.400'}
                      borderColor={'brand.200'}
                      className="publicsans-normal-black-14px">
                      Ativo
                    </Text>
                  </VStack>

                  <HStack
                    flex={0.5}
                    justifyContent={'end'}
                    w={'100%'}
                    alignContent={'center'}
                    hidden={loggedUser.user_role === RoleTypes.MONITOR}
                    className="edit-container-detalhamento-aluno">
                    <Text className="valign-text-middle-detalhamento-aluno publicsans-bold-nevada-14px">{'Editar'}</Text>
                    <Image
                      height={'52px'}
                      width={'52px'}
                      src={'/img/mode-edit@2x.png'}
                      alt="Mode edit"
                      onClick={modalDisclosure.onOpen}
                    />
                    {modalDisclosure.isOpen && (
                      <GetModalEditStudent
                        loading={loading}
                        setLoading={setLoading}
                        studentData={studentData}
                        setStudentData={setStudentData}
                        modalDisclosure={modalDisclosure}
                      />
                    )}
                  </HStack>
                </HStack>

                <Spacer />

                <HStack
                  alignItems={'start'}
                  justifyContent={'space-between'}
                  width={'100%'}
                  flexDir={{ md: 'row', base: 'column' }}>
                  <HStack alignItems={'start'} flexWrap={'wrap'} flexDir={{ md: 'row', base: 'column' }}>
                    <Text alignSelf={'center'} className="publicsans-bold-nevada-10px">
                      TURMAS VINCULADAS
                    </Text>
                    <HandleGroups />
                  </HStack>

                  <HStack hidden={userCanRemoveStudent}>
                    <div className={'publicsans-bold-nevada-14px'}> Remover aluno?</div>
                    <div>
                      <Popover
                        isOpen={popoverDisclosure.isOpen}
                        onOpen={popoverDisclosure.onOpen}
                        onClose={popoverDisclosure.onClose}
                        placement="bottom-end"
                        closeOnBlur={false}>
                        <PopoverTrigger>
                          <Image src={'/img/deleteIcon.svg'} minWidth={'25px'} marginRight={'15px'} cursor={'pointer'} />
                        </PopoverTrigger>
                        <PopoverContent maxWidth={'300px'} width={['320px', '448px']} borderRadius={'10px'}>
                          <PopoverArrow />
                          <PopoverHeader h={'100%'}>
                            <Text className={'text-popover'}>De quais turmas você gostaria de remover este aluno ?</Text>
                          </PopoverHeader>
                          <PopoverBody>
                            <MultiSelect
                              placeholder={loading ? 'Aguarde um momento...' : 'Selecione turmas desse aluno'}
                              onChange={(e) => {
                                setGroupsToRemoveFrom(e)
                              }}
                              borderColor={'#d7e3fb'}
                              backgroundColor={'white'}
                              isDisabled={loading}
                              options={studentGroups}
                              value={groupsToRemoveFrom}
                              id={'turmas-student'}
                              w={'100%'}
                            />
                          </PopoverBody>
                          <PopoverFooter alignSelf={'center'} width={'100%'}>
                            <VStack width={'100%'}>
                              <Button
                                backgroundColor={'#0A41D6'}
                                color={'white'}
                                width={'100%'}
                                height={'48px'}
                                isLoading={loading}
                                onClick={async () => {
                                  try {
                                    setLoading(true)
                                    await deleteApiV1StudentRemoveFromGroups({
                                      schoolId: loggedUser.schoolId,
                                      userId: alunoID,
                                      groups: groupsToRemoveFrom.map((group) => group.id),
                                    })
                                    toast.success('Aluno removido com sucesso')
                                  } finally {
                                    setLoading(false)
                                    navigate(-1)
                                  }
                                }}>
                                {' '}
                                Confirmar Remoção
                              </Button>
                              <Button
                                backgroundColor={'#f9faff'}
                                borderColor={'#648BF7'}
                                border={'1px'}
                                borderRadius={'8px'}
                                color={'#062989'}
                                height={'48px'}
                                width={'100%'}
                                isDisabled={loading}
                                onClick={popoverDisclosure.onClose}>
                                Cancelar
                              </Button>
                            </VStack>
                          </PopoverFooter>
                        </PopoverContent>
                      </Popover>
                    </div>
                  </HStack>
                </HStack>
              </VStack>
            </Box>

            <HStack overflowX={'auto'} w={'100%'} height={'100%'} className="frame-2595-detalhamento-aluno">
              <div
                className={`frame-2471-detalhamento-aluno ${selectedTab === 'redacoes' ? 'tab-border' : ''}`}
                onClick={() => changeSelectedTab('redacoes')}>
                <Text
                  textAlign={'center'}
                  className={`${selectedTab === 'redacoes' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                  Redações
                </Text>
              </div>
              <div
                className={`frame-2470-detalhamento-aluno ${selectedTab === 'atividades' ? 'tab-border' : ''}`}
                onClick={() => changeSelectedTab('atividades')}>
                <Text
                  textAlign={'center'}
                  className={`${selectedTab === 'atividades' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                  Atividades vinculadas
                </Text>
              </div>
            </HStack>
            {!loading && selectedTab === 'redacoes' && (
              <Box zIndex={'auto'} className={'redacoes-container-detalhamento-aluno-style'}>
                <div className="search-filter-detalhamento-aluno">
                  <SearchComponent filterText={essaysFilterText} handleSearchInputChange={handleEssaysSearchInputChange} />
                  {essays.length > 0 ? (
                    <SharedTablePagination
                      data={essays}
                      itemsPerPage={5}
                      columns={[
                        {
                          title: 'Status',
                          render: (itemData) => <HandleStatusInput status={itemData.status} />,
                        },
                        {
                          title: 'Data de Entrega',
                          render: (itemData) => <HandleSubmittedDate dateSubmitted={itemData.dateSubmitted} />,
                        },
                        {
                          title: 'Atividade',
                          render: (itemData) => <HandleGroup groupName={itemData.activitySubject} />,
                        },
                        {
                          title: 'Detalhes',
                          render: (itemData) => <HandleDetails essayId={itemData.essayId} />,
                        },
                      ]}
                    />
                  ) : (
                    <NoContentContainer>Este aluno ainda não entregou redações.</NoContentContainer>
                  )}
                </div>
              </Box>
            )}

            {!loading && selectedTab === 'atividades' && (
              <div className={'redacoes-container-detalhamento-aluno-style'}>
                <div className="search-filter-detalhamento-aluno">
                  <SearchComponent
                    filterText={activitiesFilterText}
                    handleSearchInputChange={handleActivitiesSearchInputChange}
                  />
                  {activities.length > 0 ? (
                    <SharedTablePagination
                      data={activities}
                      itemsPerPage={5}
                      columns={[
                        {
                          title: 'Atividade',
                          render: (itemData) => (
                            <div className={'table-cell-container publicsans-normal-black-14px'}>{itemData.subject}</div>
                          ),
                        },
                        {
                          title: 'Tema',
                          render: (itemData) => (
                            <div className={'table-cell-container publicsans-normal-black-14px'}>{itemData.theme.name}</div>
                          ),
                        },
                        {
                          title: 'Data de início',
                          render: (itemData) => <HandleSubmittedDate dateSubmitted={itemData.validFrom} />,
                        },
                        {
                          title: 'Data limite de entrega',
                          render: (itemData) => <HandleSubmittedDate dateSubmitted={itemData.deadline} />,
                        },
                        {
                          title: 'Detalhes',
                          render: (itemData) => <HandleActivityDetails activityId={itemData.id} />,
                        },
                      ]}
                    />
                  ) : (
                    <NoContentContainer>Este aluno possui nenhuma atividade associada a ele.</NoContentContainer>
                  )}
                </div>
              </div>
            )}
          </VStack>

          <Box mb={5} className={'student-details-style'}>
            <Text mt={'16px'} className={'publicsans-normal-resolution-blue-24px'}>
              Dados do aluno
            </Text>

            <StudentDetails
              title="Email"
              imgPath="/img/message-icon.svg"
              details={loading ? '' : studentData.email || 'Não informado'}
            />
            <StudentDetails
              title="Telefone"
              imgPath="/img/phone-icon.svg"
              details={loading ? '' : studentData.phoneNumber || 'Não informado'}
            />
          </Box>
        </HStack>
      </VStack>
    </div>
  )
}

export default StudentDetails

function Frame25802(props) {
  const { children } = props

  return (
    <div className="frame-2580-1-detalhamento-aluno">
      <div className="x1-ano-a-detalhamento-aluno valign-text-middle-detalhamento-aluno publicsans-normal-black-12px">
        {children}
      </div>
    </div>
  )
}
