import { format, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import '../GroupDetails.css'
import { useAddToHistory } from '@/utils/useNavigationHooks'
import {
  Box,
  Button,
  HStack,
  Image,
  Popover,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  deleteGroup,
  getActivityInformation,
  getEssaysInformation,
  getGroupInformation,
  getStudentInformation,
} from '../../../apis/groupApi/GroupApi'
import GetModalEditGroup from '../../../components/GetModalEditGroup'
import NoContentContainer from '../../../components/NoContentContainer'
import PageSpinner from '../../../components/PageSpinner'
import SearchComponent from '../../../components/SearchComponent'
import SharedTablePagination from '../../../components/SharedTablePagination'
import StudentInvitePopover from '../../../components/StudentInvitePopover'
import useLoggedUserStore from '../../../store/useLoggedUserStore'
import FontsUtils from '../../../theme/foundations/FontsUtils'
import sanitizedDate from '../../../utils/DateSatinizerInput'
import { RoleTypes } from '../../../utils/RoleTypes'
import { essayStatus } from '../../../utils/essayStatus'
import { queryClient } from '../../../utils/queryClient'

function GroupDetailsTeacher({ returnUrl }) {
  const { turmaID } = useParams()
  const location = useLocation()
  const { loggedUser } = useLoggedUserStore()

  // Capture the schoolId from the parent component (school pages) or use what comes from the user data (teachers profiles)
  const schoolId = location.state?.schoolId || loggedUser.schoolId

  const navigate = useNavigate()
  const [groupsData, setGroupsData] = useState({})
  const [essaysData, setEssaysData] = useState([])
  const [activityData, setActivityData] = useState([])
  const [activity, setActivity] = useState([])
  const [studentData, setStudentData] = useState([])
  const [student, setStudent] = useState([])
  const [activeStudents, setActiveStudents] = useState([])
  const [finishedEssays, setFinishedEssays] = useState([])
  const modalDisclosure = useDisclosure()
  const [loading, setLoading] = useState(true)
  const [selectedTab, setSelectedTab] = useState('alunos')
  const popoverDisclosure = useDisclosure()
  const [filterTextStudent, setFilterTextStudent] = useState('')
  const [filterTextActivity, setFilterTextActivity] = useState('')
  const [userCanRemoveGroup] = useState(loggedUser.user_role !== 'adm' && loggedUser.user_role !== 'independentteacher')

  async function fetchInitialData() {
    const [studentsInformation, activityInformation, essaysInformation, groupInformation] = await Promise.all([
      getStudentInformation(turmaID, schoolId),
      getActivityInformation(turmaID),
      getEssaysInformation(turmaID),
      getGroupInformation(turmaID, schoolId),
    ])

    return {
      studentsInformation,
      activityInformation,
      essaysInformation,
      groupInformation,
    }
  }

  async function fetchData() {
    setLoading(true)
    fetchInitialData().then(({ studentsInformation, activityInformation, essaysInformation, groupInformation }) => {
      setStudentData(studentsInformation)
      setActivityData(activityInformation)
      setEssaysData(essaysInformation)
      setGroupsData(groupInformation)

      setLoading(false)
    })
  }

  useAddToHistory()

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    handleRender()
  }, [loading])

  function handleRender() {
    studentGeneratorsAtivos()
    essaysDoneGenerator()
  }

  useEffect(() => {
    studentGeneratorsAtivos()
  }, [studentData])

  useEffect(() => {
    setStudent(
      studentData.filter((item) => {
        return item.name.toLowerCase().includes(filterTextStudent.toLowerCase())
      }),
    )
  }, [filterTextStudent, studentData])

  useEffect(() => {
    setActivity(
      activityData.filter((item) => {
        const themeMatch = item.theme.name.toLowerCase().includes(filterTextActivity.toLowerCase())

        const activityMatch = item.subject.toLowerCase().includes(filterTextActivity.toLowerCase())

        const dateMatch = item.deadline && sanitizedDate(item.deadline).includes(filterTextActivity)

        return themeMatch || activityMatch || dateMatch
      }),
    )
  }, [filterTextActivity, activityData])

  function essaysStatusGeneratorByStudent(student) {
    const countSubmitted = student.essays.filter(
      (essay) =>
        essay.status === Number.parseInt(Object.keys(essayStatus).find((key) => essayStatus[key].label === 'Submetida')),
    ).length
    const countReviewed = student.essays.filter(
      (essay) =>
        essay.status === Number.parseInt(Object.keys(essayStatus).find((key) => essayStatus[key].label === 'Revisada')),
    ).length
    const countClosed = student.essays.filter(
      (essay) =>
        essay.status === Number.parseInt(Object.keys(essayStatus).find((key) => essayStatus[key].label === 'Concluída')),
    ).length

    const submittedText = countSubmitted === 1 ? `${countSubmitted} submetida` : `${countSubmitted} submetidas`
    const reviewedText = countReviewed === 1 ? `${countReviewed} revisada` : `${countReviewed} revisadas`
    const closedText = countClosed === 1 ? `${countClosed} concluída` : `${countClosed} concluídas`

    return (
      <Box pl={2} pr={2} className="table-cell-container">
        <Box className="essay-status in-grading-essay-status publicsans-normal-nevada-14px">
          <Text>{submittedText}</Text>
        </Box>
        <Box className="essay-status in-review-essay-status publicsans-normal-nevada-14px">
          <Text>{reviewedText}</Text>
        </Box>
        <Box className="essay-status finished-essay-status publicsans-normal-nevada-14px">
          <Text>{closedText}</Text>
        </Box>
      </Box>
    )
  }

  function essaysStatusGeneratorByActivity(activityData) {
    const countSubmitted = activityData.amountEssaysSubmitted
    const countReviewed = activityData.amountEssaysReviewed
    const countClosed = activityData.amountEssaysClosed

    const submittedText = countSubmitted === 1 ? `${countSubmitted} submetida` : `${countSubmitted} submetidas`
    const reviewedText = countReviewed === 1 ? `${countReviewed} revisada` : `${countReviewed} revisadas`
    const closedText = countClosed === 1 ? `${countClosed} concluída` : `${countClosed} concluídas`

    return (
      <HStack className="table-cell-container">
        <Box className="essay-status in-grading-essay-status publicsans-normal-nevada-14px">
          <Text>{submittedText}</Text>
        </Box>
        <Box className="essay-status in-review-essay-status publicsans-normal-nevada-14px">
          <Text>{reviewedText}</Text>
        </Box>
        <Box className="essay-status finished-essay-status publicsans-normal-nevada-14px">
          <Text>{closedText}</Text>
        </Box>
      </HStack>
    )
  }

  function textHandler(text) {
    return (
      <Box height={'84px'} alignContent={'center'}>
        <Text fontSize="sm" color={FontsUtils.fontGray.color} noOfLines={3} fontFamily={FontsUtils.fontPrimary.fontFamily}>
          {text}
        </Text>
      </Box>
    )
  }

  function arrowsGenerator(link) {
    return (
      <Box cursor={'pointer'} className="atividade-txt-6-detalhamento-turma" onClick={() => navigate(link)}>
        <Image className="ic-arrowright-detalhamento-turma" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
      </Box>
    )
  }

  function sendDateGenerator(dataDeEnvio) {
    const dataNula = ''
    if (dataDeEnvio === undefined || dataDeEnvio === null) {
      return <AtividadeTxt20 date={dataNula} />
    }
    const dateWithoutFractions = dataDeEnvio.split('.')[0] // Remover as frações de segundos
    const parsedDate = parseISO(dateWithoutFractions)
    const formattedDate = format(parsedDate, "dd/MM/yyyy 'às' HH:mm")
    return <AtividadeTxt20 date={formattedDate} />
  }

  function studentGeneratorsAtivos() {
    if (!Array.isArray(studentData) || studentData.length === 0) {
      setActiveStudents(0)
    } else {
      setActiveStudents(studentData.length)
    }
  }

  function essaysDoneGenerator() {
    let totalFinishedEssays = 0
    essaysData.map((item) => {
      if (!Array.isArray(item.status) && item.status === 40) {
        totalFinishedEssays = totalFinishedEssays + 1
      }
    })
    setFinishedEssays(totalFinishedEssays)
  }

  function changeSelectedTab(tabName) {
    setSelectedTab(tabName)
  }

  if (loading) return <PageSpinner />

  return (
    <HStack gap={2} width={'90%'} pb={5} className={'content-max-size'} flexDir={{ md: 'row', base: 'column' }}>
      <VStack width={'100%'} mt={'32px'} gap={5}>
        <Box className="tema-detalhamento-turma" height={'100%'}>
          <HStack w={'100%'} height={'100%'}>
            <VStack width={'100%'} alignSelf={'start'} alignItems={'start'}>
              <Text className="publicsans-bold-nevada-10px">TURMA</Text>
              <Text wordBreak={'break-word'} className="publicsans-normal-absolute-zero-24px">
                {groupsData?.name}
              </Text>
            </VStack>

            <VStack w={'100%'} alignItems={'end'} gap={10}>
              <HStack hidden={loggedUser.user_role === RoleTypes.MONITOR} className="editar-detalhamento-turma">
                <Text className="publicsans-bold-nevada-14px">Editar</Text>
                <Image
                  className="mode-edit-detalhamento-atividade"
                  src="/img/mode-edit@2x.png"
                  alt="Mode edit"
                  onClick={modalDisclosure.onOpen}
                />
                {modalDisclosure.isOpen && (
                  <GetModalEditGroup
                    modalDisclosure={modalDisclosure}
                    groupsData={groupsData}
                    setGroupsData={setGroupsData}
                    schoolId={schoolId}
                  />
                )}
              </HStack>

              <HStack justifyContent={'end'} width={'100%'} hidden={userCanRemoveGroup}>
                <div className={'publicsans-bold-nevada-14px'}> Apagar turma?</div>
                <Box>
                  <Popover
                    isOpen={popoverDisclosure.isOpen}
                    onOpen={popoverDisclosure.onOpen}
                    onClose={popoverDisclosure.onClose}
                    placement="bottom-end"
                    closeOnBlur={false}>
                    <PopoverTrigger>
                      <Image src={'/img/deleteIcon.svg'} maxW={'42px'} maxH={'52px'} cursor={'pointer'} pr={4} />
                    </PopoverTrigger>
                    <PopoverContent maxWidth={'300px'} width={['290px', '448px']} borderRadius={'10px'}>
                      <PopoverHeader>
                        <div className={'text-popover'}>Confirme Remoção da turma</div>
                      </PopoverHeader>
                      <PopoverFooter alignSelf={'center'} marginTop={'40px'} width={'100%'}>
                        <VStack width={'100%'}>
                          <Button
                            colorScheme={'red'}
                            isLoading={loading}
                            height={'48px'}
                            width={'100%'}
                            onClick={async () => {
                              try {
                                setLoading(true)
                                await deleteGroup(turmaID, schoolId)
                                await queryClient.invalidateQueries({ queryKey: ['/api/v1/teacher'] })
                              } finally {
                                setLoading(false)
                                navigate(returnUrl)
                              }
                            }}>
                            Remover
                          </Button>
                          <Button
                            variant={'outline'}
                            height={'48px'}
                            width={'100%'}
                            isDisabled={loading}
                            onClick={popoverDisclosure.onClose}>
                            Cancelar
                          </Button>
                        </VStack>
                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>
                </Box>
              </HStack>
            </VStack>
          </HStack>
        </Box>
        <HStack
          flexDir={{ md: 'row', base: 'column' }}
          height={{ md: '90px', base: '180px' }}
          className="status-detalhamento-turma">
          <Box w={'100%'} className="frame-243-detalhamento-turma">
            <Image className="folder-2851794-detalhamento-turma" src="/img/folder-2851794-3@2x.png" alt="folder-icon" />
            <Text className="publicsans-bold-nevada-14px">Redações feitas</Text>
            <Text className="publicsans-extra-bold-absolute-zero-32px">{finishedEssays}</Text>
          </Box>
          <Box w={'100%'} className="frame-243-detalhamento-turma">
            <Image className="folder-2851794-detalhamento-turma" src="/img/folder-2851794-1@2x.png" alt="folder-icon" />
            <Text className="publicsans-bold-nevada-14px">Alunos ativos</Text>
            <Text className="publicsans-extra-bold-absolute-zero-32px">{activeStudents}</Text>
          </Box>
        </HStack>
        <HStack overflowX={'auto'} w={'100%'} height={'100%'} className="frame-2595-detalhamento-turma">
          <Box width={'100%'} inlineSize={['fit-content', 'inherit']} className="switch-detalhamento-turma">
            <div
              className={`frame-2470-detalhamento-turma ${selectedTab === 'alunos' ? 'tab-border' : ''}`}
              onClick={() => changeSelectedTab('alunos')}>
              <div className={`${selectedTab === 'alunos' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'}`}>
                Alunos
              </div>
            </div>
            <div
              className={`frame-2469-detalhamento-turma ${selectedTab === 'atividades vinculadas' ? 'tab-border' : ''}`}
              onClick={() => changeSelectedTab('atividades vinculadas')}>
              <div
                className={`${
                  selectedTab === 'atividades vinculadas' ? 'publicsans-bold-nevada-16px' : 'publicsans-normal-gray-16px'
                }`}>
                Atividades vinculadas
              </div>
            </div>
          </Box>
        </HStack>
        <Box width={'100%'}>
          {selectedTab === 'alunos' && (
            <VStack gap={3} width={'100%'}>
              <HStack width={'100%'} alignItems="end">
                <div className="search-filter-detalhamento-turma">
                  <SearchComponent
                    filterText={filterTextStudent}
                    handleSearchInputChange={(e) => setFilterTextStudent(e.target.value)}
                  />
                </div>
                <div>
                  <StudentInvitePopover turmaID={turmaID} schoolId={schoolId} setStudentData={setStudentData} />
                </div>
              </HStack>
              {student.length ? (
                <SharedTablePagination
                  data={student}
                  itemsPerPage={5}
                  columns={[
                    {
                      title: 'Aluno',
                      render: (studentData) => <div>{textHandler(studentData.name)}</div>,
                    },
                    {
                      title: 'Email',
                      render: () => <div>{textHandler(studentData.email)}</div>,
                    },
                    {
                      title: 'Numero',
                      render: () => <div>{textHandler(studentData.phoneNumber)}</div>,
                    },
                    {
                      title: 'Redações',
                      render: (studentData) => <div>{essaysStatusGeneratorByStudent(studentData)}</div>,
                    },
                    {
                      title: 'Detalhes',
                      render: (studentData) => <div>{arrowsGenerator(`/detalhamento-aluno/${studentData.id}`)}</div>,
                    },
                  ]}
                />
              ) : (
                <NoContentContainer>Não há alunos cadastrados nesta turma.</NoContentContainer>
              )}
            </VStack>
          )}
          {selectedTab === 'atividades vinculadas' && (
            <VStack gap={2} width={'100%'}>
              <HStack width={'100%'}>
                <div className="search-filter-detalhamento-turma">
                  <SearchComponent
                    filterText={filterTextActivity}
                    handleSearchInputChange={(e) => setFilterTextActivity(e.target.value)}
                  />
                </div>
              </HStack>
              {activity.length ? (
                <SharedTablePagination
                  data={activity}
                  itemsPerPage={5}
                  columns={[
                    {
                      title: 'Atividade',
                      render: (activityData) => <div>{textHandler(activityData.subject)}</div>,
                    },
                    {
                      title: 'Tema',
                      render: (activityData) => <div>{textHandler(activityData.theme.name)}</div>,
                    },
                    {
                      title: 'Andamento',
                      render: (activityData) => <div>{essaysStatusGeneratorByActivity(activityData)}</div>,
                    },
                    {
                      title: 'Entrega final',
                      render: (activityData) => {
                        const generatedData = sendDateGenerator(activityData.deadline)
                        return <div>{generatedData}</div>
                      },
                    },
                    {
                      title: 'Detalhes',
                      render: (activityData) => <div>{arrowsGenerator(`/detalhamento-atividade/${activityData.id}`)}</div>,
                    },
                  ]}
                />
              ) : (
                <NoContentContainer>Não há atividades cadastradas nesta turma.</NoContentContainer>
              )}
            </VStack>
          )}
        </Box>
      </VStack>
      <VStack
        minWidth={'300px'}
        width={'100%'}
        mt={[5, 0]}
        mb={[5, 0]}
        pl={5}
        className="frame-2591-detalhamento-turma"
        alignSelf={{ md: 'start', base: 'center' }}>
        <TitleCta2 />
        <div className="frame-2547-detalhamento-turma">
          <div className="email-detalhamento-turma">
            <p id="editableContent" className="bem-vindoa-verif-detalhamento-turma publicsans-normal-nevada-16px">
              {groupsData?.description && groupsData?.description.trim() !== ''
                ? groupsData?.description
                : 'Não há mensagem personalizada'}
            </p>
          </div>
        </div>
      </VStack>
    </HStack>
  )
}

export default GroupDetailsTeacher

function TitleCta2() {
  return (
    <div className="title-cta-detalhamento-turma">
      <div className="title-detalhamento-turma">
        <h1 className="publicsans-normal-resolution-blue-24px">{'Mensagem personalizada'}</h1>
      </div>
    </div>
  )
}

function AtividadeTxt20(props) {
  const { date } = props

  return (
    <div className="atividade-txt-detalhamento-turma">
      <Frame25805 date={date} />
    </div>
  )
}

function Frame25805(props) {
  const { date } = props

  return (
    <div className="frame-2580-1-detalhamento-turma">
      {date !== '' ? (
        <img className="calendar-2851774-1-detalhamento-atividade" src={'/img/calendar-icon.svg'} alt="calendar-2851774" />
      ) : (
        ''
      )}
      <div className="publicsans-normal-black-12px">{date}</div>
    </div>
  )
}
