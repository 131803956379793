import { usePostApiV1Essay } from '@/services/essay'
import type { ActivityViewModel } from '@/services/types'
import {
  Button,
  Hide,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
} from '@chakra-ui/react'

type CreateEssayProps = {
  navigateTo: (url: string) => void
  activity: ActivityViewModel
  isHidden: boolean
}

export const CreateEssay = (props: CreateEssayProps) => {
  const { navigateTo, activity, isHidden } = props
  const postApiV1Essay = usePostApiV1Essay()

  const handleCreateEssay = (groupId?: string) => {
    const data = {
      activityId: activity?.id,
      groupId,
      teacherId: activity?.teacher?.id,
      draft: '',
      isText: true,
    }
    postApiV1Essay.mutate({ data }, { onSuccess: (response) => navigateTo(`/redacao-estudante/${response.data.message}`) })
  }

  const studentGroupsLength = activity.groups?.length || 0
  const essayGroupIds = activity.essays?.map((essay) => essay.group?.id) || []
  const groupsWithoutEssay = activity.groups?.filter((group) => !essayGroupIds?.includes(group.id))

  if (!groupsWithoutEssay || groupsWithoutEssay.length === 0) return

  if (studentGroupsLength >= 2 && groupsWithoutEssay.length === 1) return //student in two groups within the same activity but already created one is not allowed to create the next

  if (groupsWithoutEssay.length === 1)
    return (
      <Button
        onClick={() => handleCreateEssay(groupsWithoutEssay[0].id)}
        variant="primary"
        gap="12px"
        padding="25px"
        maxW="200px"
        flex="1"
        hidden={isHidden}
        isLoading={postApiV1Essay.isPending}>
        <Image className="ic-add" src={'/img/ic-add-2@2x.png'} alt="ic-add" />
        <Text fontSize="sm">
          Criar <Hide below="md">Redação</Hide>
        </Text>
      </Button>
    )

  return (
    <Popover placement="bottom-end" variant="responsive">
      <PopoverTrigger>
        <Button variant="primary" gap="12px" padding="25px" maxW="200px" hidden={isHidden} isLoading={postApiV1Essay.isPending}>
          <Image className="ic-add" src={'/img/ic-add-2@2x.png'} alt="ic-add" />
          <Text fontSize="sm">
            Criar <Hide below="md">Redação</Hide>
          </Text>
        </Button>
      </PopoverTrigger>
      <PopoverContent shadow="lg" width="auto">
        <PopoverHeader>
          <Text color="gray.500">Selecione a turma</Text>
        </PopoverHeader>
        <PopoverBody p={0}>
          <VStack gap={0}>
            {groupsWithoutEssay?.map((group) => (
              <Button
                key={group.id}
                variant="ghost"
                justifyContent="flex-start"
                borderRadius="none"
                fontWeight="normal"
                width="100%"
                onClick={() => handleCreateEssay(group.id)}>
                {group.name}
              </Button>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
