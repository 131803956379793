import fontsUtils from '@/theme/foundations/FontsUtils.ts'
import {
  Button,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react'
import { LuChevronDown } from 'react-icons/lu'

export function ThemeAiAssistantPopover({ isPopoverOpen, closePopover, openPopover, suggestedThemes, setValue }) {
  return (
    <Popover isOpen={isPopoverOpen} placement="bottom-end" variant="responsive" closeOnBlur={false} onClose={closePopover}>
      <PopoverTrigger>
        <IconButton variant="primary" size="sm" aria-label={'ia-theme-generation'} onClick={openPopover}>
          <LuChevronDown />
        </IconButton>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader sx={fontsUtils.publicSansBoldAbsoluteZero}>Sugestões de Temas</PopoverHeader>
        <PopoverBody>
          <VStack align="start" gap={0}>
            {suggestedThemes.map((theme) => (
              <Button
                key={theme.id}
                variant="ghost"
                whiteSpace={'break-spaces'}
                textAlign={'left'}
                width={'100%'}
                height={'100%'}
                justifyContent={'start'}
                p={3}
                onClick={() => {
                  setValue('subject', theme.title)
                  setValue('description', theme.description)
                  closePopover()
                }}>
                {theme.title}
              </Button>
            ))}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
