import { Button, Text } from '@chakra-ui/react'
import ReactDropzone from 'react-dropzone'
import { MdUploadFile } from 'react-icons/md'
import { toast } from 'react-toastify'

type EssayCreateUploadImageProps = {
  onDrop: (file: File) => void
  essayImageUrl?: string | null
  isLoading: boolean
}

const sizes = {
  minWidth: 1000,
  maxWidth: 3500,
  minHeight: 1400,
  maxHeight: 5000,
}
export const EssayCreateUploadImage = ({ onDrop, isLoading }: EssayCreateUploadImageProps) => {
  const validateImageDimensions = (file: File, callback: (file: File) => void) => {
    const img = new Image()
    img.src = URL.createObjectURL(file)

    img.onload = () => {
      const { width, height } = img

      const isWithinDimensions = (width: number, height: number) =>
        width >= sizes.minWidth && width <= sizes.maxWidth && height >= sizes.minHeight && height <= sizes.maxHeight

      if (isWithinDimensions(width, height)) return callback(file)
      toast.error(`A imagem deve ter entre ${sizes.minWidth}x${sizes.minHeight} e ${sizes.maxWidth}x${sizes.maxHeight} pixels.`)
    }

    img.onerror = () => toast.error('Erro ao carregar a imagem. Por favor, tente novamente.')
  }

  return (
    <ReactDropzone
      accept={{
        'image/png': ['.png'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
      }}
      onDropAccepted={(acceptedFiles) => {
        const file = acceptedFiles[0]
        validateImageDimensions(file, onDrop)
      }}
      multiple={false}>
      {({ getRootProps, getInputProps }) => (
        <>
          <Text color="gray.400" fontSize="xl" userSelect="none" lineHeight="200%">
            ou
          </Text>
          <Button
            {...getRootProps()}
            leftIcon={<MdUploadFile />}
            isLoading={isLoading}
            pointerEvents="auto"
            color="gray.400"
            fontWeight="normal"
            fontSize="xl">
            envie uma imagem
          </Button>
          <input {...getInputProps()} />
        </>
      )}
    </ReactDropzone>
  )
}
