export const BACKEND_API = process.env.REACT_APP_PROD_BACKEND || process.env.REACT_APP_HOMOLOG_BACKEND

export const USER_ROLE_CODE = {
  OWNER: 0,
  ADM: 10,
  INDEPENDENT_TEACHER: 15,
  MONITOR: 17,
  TEACHER: 20,
  STUDENT: 30,
}

export const USER_ROLES_STRING = {
  [USER_ROLE_CODE.OWNER]: 'owner',
  [USER_ROLE_CODE.ADM]: 'adm',
  [USER_ROLE_CODE.INDEPENDENT_TEACHER]: 'independentteacher',
  [USER_ROLE_CODE.MONITOR]: 'monitor',
  [USER_ROLE_CODE.TEACHER]: 'teacher',
  [USER_ROLE_CODE.STUDENT]: 'student',
}

export const ALLOWED_USERS = {
  USERS: [USER_ROLES_STRING[USER_ROLE_CODE.OWNER]],
  PLANS: [USER_ROLES_STRING[USER_ROLE_CODE.OWNER]],
  PROMPTS: [USER_ROLES_STRING[USER_ROLE_CODE.OWNER]],
  THEME_PROMPTS: [USER_ROLES_STRING[USER_ROLE_CODE.OWNER]],
  SYSTEM: [USER_ROLES_STRING[USER_ROLE_CODE.OWNER]],
  INICIO: [
    USER_ROLES_STRING[USER_ROLE_CODE.TEACHER],
    USER_ROLES_STRING[USER_ROLE_CODE.STUDENT],
    USER_ROLES_STRING[USER_ROLE_CODE.INDEPENDENT_TEACHER],
    USER_ROLES_STRING[USER_ROLE_CODE.MONITOR],
    USER_ROLES_STRING[USER_ROLE_CODE.ADM],
  ],
  ESCOLAS: [USER_ROLES_STRING[USER_ROLE_CODE.ADM]],
  ATIVIDADES: [
    USER_ROLES_STRING[USER_ROLE_CODE.TEACHER],
    USER_ROLES_STRING[USER_ROLE_CODE.INDEPENDENT_TEACHER],
    USER_ROLES_STRING[USER_ROLE_CODE.MONITOR],
  ],
  TEMAS: [
    USER_ROLES_STRING[USER_ROLE_CODE.TEACHER],
    USER_ROLES_STRING[USER_ROLE_CODE.INDEPENDENT_TEACHER],
    USER_ROLES_STRING[USER_ROLE_CODE.MONITOR],
  ],
  INSTITUTION: [USER_ROLES_STRING[USER_ROLE_CODE.INDEPENDENT_TEACHER]],
  TURMAS: [
    USER_ROLES_STRING[USER_ROLE_CODE.TEACHER],
    USER_ROLES_STRING[USER_ROLE_CODE.INDEPENDENT_TEACHER],
    USER_ROLES_STRING[USER_ROLE_CODE.MONITOR],
    USER_ROLES_STRING[USER_ROLE_CODE.STUDENT],
  ],
  MONITORES: [USER_ROLES_STRING[USER_ROLE_CODE.INDEPENDENT_TEACHER]],
  PROFILE: [
    USER_ROLES_STRING[USER_ROLE_CODE.ADM],
    USER_ROLES_STRING[USER_ROLE_CODE.INDEPENDENT_TEACHER],
    USER_ROLES_STRING[USER_ROLE_CODE.MONITOR],
    USER_ROLES_STRING[USER_ROLE_CODE.TEACHER],
    USER_ROLES_STRING[USER_ROLE_CODE.STUDENT],
  ],
}

export const USER_ROLE = {
  [USER_ROLE_CODE.OWNER]: 'Owner',
  [USER_ROLE_CODE.ADM]: 'Administrador',
  [USER_ROLE_CODE.INDEPENDENT_TEACHER]: 'Professor Independente',
  [USER_ROLE_CODE.MONITOR]: 'Monitor',
  [USER_ROLE_CODE.TEACHER]: 'Professor',
  [USER_ROLE_CODE.STUDENT]: 'Estudante',
}

export const userRolesOptions = Object.entries(USER_ROLE).map(([value, label]) => ({
  value: Number.parseInt(value),
  label,
}))
