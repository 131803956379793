import { MarkdownRenderer } from '@/components/MarkdownRenderer'
import { usePostApiV1AssistantAssistantUsage, usePostApiV1AssistantAssistantUsageReact } from '@/services/assistant'
import type { AssistantUsageContentViewModel, AssistantUsageReactionViewModel } from '@/services/types'
import FontsUtils from '@/theme/foundations/FontsUtils.ts'
import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import useLoggedUserStore from '../../store/useLoggedUserStore'

type AISupportProps = {
  essayId?: string
  teacherId?: string
}

export const AISupport = (props: AISupportProps) => {
  const { essayId, teacherId } = props
  const [assistantOnHover, setAssistantOnHover] = useState(false)
  const [assistant, setAssistant] = useState<AssistantUsageContentViewModel>()
  const { loggedUser } = useLoggedUserStore()
  const { isOpen: assistantModalIsOpen, onOpen: assistantModalOnOpen, onClose: assistantModalOnClose } = useDisclosure()
  const [userReaction, setUserReaction] = useState<number>()
  const { mutate: postAssistantUsage, isPending } = usePostApiV1AssistantAssistantUsage({
    mutation: {
      onSuccess: (data) => {
        if (!data?.data) {
          toast.error('Erro ao buscar dados da AI')
          return
        }
        // @ts-ignore
        setAssistant(data.data.message)
      },
      onError: (_error) => {
        toast.error('Ocorreu um erro ao solicitar correção do assistente.')
      },
    },
  })

  useEffect(() => {
    setUserReaction(Number(assistant?.reaction))
  }, [assistant?.reaction])

  const postApiV1AssistantUsageReact = usePostApiV1AssistantAssistantUsageReact()

  const sendReview = async (reaction: number) => {
    setUserReaction(reaction)
    const data: AssistantUsageReactionViewModel = {
      id: assistant?.id,
      reaction,
    }
    postApiV1AssistantUsageReact.mutate({ data }, { onSuccess: () => toast.success('Obrigado pela avaliação!') })
  }

  const onAssistantUse = () => {
    if (!loggedUser.schoolId || !teacherId || !essayId) return
    postAssistantUsage({
      data: {
        schoolId: loggedUser.schoolId,
        teacherId,
        essayId,
      },
    })

    assistantModalOnOpen()
  }
  if (!essayId || !teacherId) return null

  return (
    <>
      <Popover trigger="hover">
        <PopoverTrigger>
          <Box
            onMouseEnter={() => setAssistantOnHover(true)}
            onMouseLeave={() => setAssistantOnHover(false)}
            _hover={{ width: '150px' }}
            transition={'width 0.5s ease'}
            position="fixed"
            bottom={4}
            left={4}
            cursor="pointer"
            display="flex"
            backgroundColor="#062989"
            justifyContent="center"
            alignItems="center"
            rounded="full"
            width={'45px'}
            height="45px">
            <HStack>
              <Text
                color={'white'}
                opacity={assistantOnHover ? '1' : '0'}
                width={assistantOnHover ? '80px' : '0px'}
                marginRight={!assistantOnHover ? '-6px' : '0px'}
                transition={'opacity 0.7s ease-out 0.5s, width 0.5s ease-out'}>
                {assistantOnHover ? 'Assistente' : ''}
              </Text>
              <Img src="/img/correcaoonline-icon-light.svg" width="15px" />
            </HStack>
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent shadow="lg" border={'0px'} borderRadius={'15px'}>
            <PopoverBody paddingY={3}>
              <Stack>
                <Text fontWeight="bold">Olá, professor 👋</Text>
                <Text fontWeight="400" fontSize="13px">
                  Corrija mais rápido com nosso suporte de IA
                </Text>
                <Divider marginY={3} color="gray.200" />
                <Flex justifyContent="space-between" onClick={() => onAssistantUse()}>
                  <HStack cursor="pointer">
                    <Img width="25px" src={'/img/folder-pen-icon.png'} />
                    <Text>Sugestões de correção com IA</Text>
                  </HStack>
                </Flex>
              </Stack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      <Modal size="6xl" isOpen={assistantModalIsOpen} onClose={assistantModalOnClose}>
        <ModalOverlay />
        <ModalContent mt={8}>
          <ModalCloseButton color="#0a41d6" mt="5px" onClick={assistantModalOnClose} />
          <ModalHeader>
            <Text sx={FontsUtils.publicSansBoldAbsoluteZero} fontSize={'xl'} fontWeight={'400'} color={'#0A41D6'}>
              Assistente de correção
            </Text>
          </ModalHeader>
          <ModalBody>
            <Stack>
              <Stack>
                <Text sx={FontsUtils.fontGray} fontWeight={'400'}>
                  Nosso assistente oferece avaliações precisas e correções instantâneas, para reduzir o tempo gasto na revisão
                  manual e elevar o nível das suas redações de maneira eficaz e eficiente.
                </Text>
              </Stack>
              <Stack padding={5} mt={4} rounded={'md'} backgroundColor={'#F9FAFF'}>
                {isPending ? (
                  <Flex justifyContent="center" alignItems="center">
                    <Spinner size="lg" color={'blue'} />
                  </Flex>
                ) : (
                  <MarkdownRenderer markdownText={assistant?.result} />
                )}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            {assistant ? (
              <>
                <HStack>
                  <Button variant={userReaction === 3 ? 'primary' : undefined} onClick={() => sendReview(3)}>
                    😍 Amei
                  </Button>
                  <Button variant={userReaction === 2 ? 'primary' : undefined} onClick={() => sendReview(2)}>
                    😊 Gostei
                  </Button>
                  <Button variant={userReaction === 1 ? 'primary' : undefined} onClick={() => sendReview(1)}>
                    😐 Nada demais
                  </Button>
                  <Button variant={userReaction === 0 ? 'primary' : undefined} onClick={() => sendReview(0)}>
                    😕 Não gostei
                  </Button>
                </HStack>
                <HStack justifyContent={'end'} width={'100%'}>
                  <HStack hidden={!assistant}>
                    <Text>Copiar texto</Text>
                    <Button
                      onClick={() => {
                        navigator.clipboard.writeText(assistant?.result || '')
                        toast.success('Sugestão copiada com sucesso!')
                      }}
                      backgroundColor={'blue'}
                      rounded={'full'}>
                      <Img width="20px" src="/img/copy.svg" />
                    </Button>
                  </HStack>
                </HStack>
              </>
            ) : null}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
