import { useGetApiV1StudentCreateInviteUrlSchoolSchoolIdGroupGroupId } from '@/services/student.ts'
import { Button, Input, TabPanel, Text, VStack } from '@chakra-ui/react'
import { toast } from 'react-toastify'

type StudentInviteLinkProps = {
  groupId: string
  schoolId: string
}

const inputStyle = {
  width: '100%',
  backgroundColor: 'white',
  border: '1px solid #d7e3fb',
  padding: '5px',
  paddingLeft: '12px',
}

export const StudentInviteByLink = (props: StudentInviteLinkProps) => {
  const { groupId, schoolId } = props

  const { data, isLoading, refetch } = useGetApiV1StudentCreateInviteUrlSchoolSchoolIdGroupGroupId(schoolId, groupId, {
    query: {
      enabled: false,
    },
  })

  const link = data?.data?.message || ''

  return (
    <TabPanel>
      <Text paddingBottom={'16px'} paddingTop={'8px'} paddingLeft={'8px'}>
        Envie este link para os seus alunos desta turma, desta forma eles poderão completar seu próprio cadastro.
      </Text>
      <VStack>
        <Input
          id="link"
          type="text"
          style={inputStyle}
          placeholder={'Link a gerar'}
          className="input-default-style"
          disabled={true}
          value={link}
        />
        <Button
          variant={'primary'}
          color={'white'}
          width={'80%'}
          height={'48px'}
          marginTop={'15px'}
          hidden={link !== ''}
          onClick={() => refetch()}
          isLoading={isLoading}>
          Gerar Link
        </Button>
        <Button
          variant={'outline'}
          width={'80%'}
          height={'48px'}
          marginTop={'15px'}
          hidden={link === ''}
          onClick={() => {
            navigator.clipboard
              .writeText(link)
              .then(() => {
                toast.success('Link copiado!')
              })
              .catch(() => {
                toast.error('Erro ao copiar link.')
              })
          }}>
          Copiar Link
        </Button>
      </VStack>
    </TabPanel>
  )
}
