import './SignUpStep2.css'
import '../../../globals.css'
import { SelectedStep } from '@/components/FormSteps/SelectedStep.tsx'
import { UnselectedStep } from '@/components/FormSteps/UnselectedStep.tsx'
import NoContentContainer from '@/components/NoContentContainer'
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Image,
  Input,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  Text,
  UnorderedList,
  useDisclosure,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import type { UserData } from 'src/pages/SignUpPage'
import * as yup from 'yup'
import IlustracaoLogin from '../../components/IlustracaoLogin'
import FontsUtils from '../../theme/foundations/FontsUtils'
import { inputPhoneMask } from '../../utils/inputMasks'

const validationSchema = yup.object({
  name: yup.string().required().min(5),
  email: yup.string().email().required(),
  phoneNumber: yup.string().required(),
  eula: yup.bool().oneOf([true], 'Você deve aceitar os termos e condições'),
})

type CadastroStep2Props = {
  userData: UserData
  handleReturn: () => void
  handlingNextStep: (userData?: UserData) => void
}

const SignUpStep2 = ({ handleReturn, handlingNextStep, userData }: CadastroStep2Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: userData.email || '',
      name: userData.name || '',
      eula: userData.eula,
      phoneNumber: userData.phoneNumber,
    },
  })

  const { isOpen: isTermsOpen, onOpen: onTermsOpen, onClose: onTermsClose } = useDisclosure()
  const { isOpen: isPrivacyOpen, onOpen: onPrivacyOpen, onClose: onPrivacyClose } = useDisclosure()

  const termsOfUseTeacher = (
    <NoContentContainer>
      <OrderedList spacing={4} padding="10px">
        <ListItem>
          <Text fontWeight="bold">Termos de Uso para Professores Independentes:</Text>
          <UnorderedList spacing={2} pl={4} styleType="none">
            <ListItem mt={4}>
              <Text fontWeight="bold">1.1. Cadastro de Professores Independentes:</Text>
              <Text>
                Professores independentes podem criar contas no sistema como entidades escolares. Eles têm o direito de criar
                turmas, adicionar alunos, atividades e temas ao sistema. Eles são responsáveis por garantir que as atividades e
                temas sejam apropriados e em conformidade com todas as leis aplicáveis.
              </Text>
            </ListItem>
            <ListItem mt={4}>
              <Text fontWeight="bold" mt={3}>
                1.2. Monitores Associados:
              </Text>
              <Text>
                Monitores podem ser associados a professores independentes para auxiliar na avaliação de redações. Eles não têm
                permissão para criar turmas, atividades, ou temas, e devem operar sob a supervisão do professor independente.
              </Text>
            </ListItem>
            <ListItem mt={4}>
              <Text fontWeight="bold" mt={3}>
                1.3. Limitações e Responsabilidade:
              </Text>
              <Text>
                Professores independentes são responsáveis por garantir que o uso do sistema por eles e por seus monitores
                esteja de acordo com todas as leis aplicáveis e com as diretrizes do sistema.
              </Text>
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" mt={4}>
            Modelo de Negócio Freemium:
          </Text>
          <UnorderedList spacing={2} pl={4} styleType="none">
            <ListItem mt={4}>
              <Text fontWeight="bold">2.1. Uso Gratuito e Limitações:</Text>
              <Text>
                O sistema oferece uma cota limitada de atividades gratuitas por mês. Detalhes sobre a quantidade exata de
                atividades e o número de alunos suportados estão disponíveis no site. Após o uso dessa cota, um plano pago será
                necessário.
              </Text>
            </ListItem>
            <ListItem mt={4}>
              <Text fontWeight="bold" mt={3}>
                2.2. Termos de Pagamento e Renovação:
              </Text>
              <Text>
                Planos pagos estão sujeitos a renovação automática, a menos que cancelados. Os usuários são responsáveis por
                cancelar suas assinaturas antes da renovação para evitar cobranças adicionais.
              </Text>
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" mt={4}>
            Ferramentas de Inteligência Artificial:
          </Text>
          <UnorderedList spacing={2} pl={4} styleType="none">
            <ListItem mt={4}>
              <Text fontWeight="bold">3.1. Sugestões e Auxílio de Correção:</Text>
              <Text>
                As ferramentas de IA fornecem sugestões e auxiliam na correção de redações. No entanto, a precisão dessas
                sugestões não é garantida, e o usuário deve usar seu julgamento final na avaliação.
              </Text>
            </ListItem>
            <ListItem mt={4}>
              <Text fontWeight="bold" mt={3}>
                3.2. Limitação de Responsabilidade:
              </Text>
              <Text>
                O sistema não se responsabiliza por decisões tomadas com base nas sugestões fornecidas pelas ferramentas de IA.
              </Text>
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" mt={4}>
            Isenção de Responsabilidade e Indenização:
          </Text>
          <UnorderedList spacing={2} pl={4} styleType="none">
            <ListItem mt={4}>
              <Text fontWeight="bold">4.1. Isenção de Responsabilidade:</Text>
              <Text>O sistema não se responsabiliza por qualquer uso indevido ou inadequado por parte dos usuários.</Text>
            </ListItem>
            <ListItem mt={4}>
              <Text fontWeight="bold" mt={3}>
                4.2. Indenização:
              </Text>
              <Text>
                Os usuários concordam em indenizar o sistema por quaisquer danos ou reclamações decorrentes do uso inadequado do
                sistema.
              </Text>
            </ListItem>
          </UnorderedList>
        </ListItem>
      </OrderedList>
    </NoContentContainer>
  )

  const termsOfUseAdm = (
    <NoContentContainer>
      <OrderedList spacing={4} padding="10px">
        <ListItem>
          <Text fontWeight="bold">Termos de Uso para Administradores Escolares:</Text>
          <UnorderedList spacing={2} pl={4} styleType="none">
            <ListItem mt={4}>
              <Text fontWeight="bold">1.1. Cadastro e Uso do Sistema:</Text>
              <Text>
                Administradores escolares podem criar uma conta no sistema para gerenciar suas escolas. Cada escola pode ter
                professores associados, que gerenciam suas turmas e alunos. O administrador é responsável pelo gerenciamento
                adequado das contas e pela conformidade com as leis e regulamentos aplicáveis.
              </Text>
            </ListItem>
            <ListItem mt={4}>
              <Text fontWeight="bold" mt={3}>
                1.2. Responsabilidade pelo Conteúdo:
              </Text>
              <Text>
                Administradores são responsáveis por garantir que seus professores, alunos e demais membros usem o sistema de
                forma adequada e em conformidade com todas as leis aplicáveis. Quaisquer conteúdos que violem a legislação ou as
                políticas internas são de responsabilidade da escola e do administrador correspondente.
              </Text>
            </ListItem>
            <ListItem mt={4}>
              <Text fontWeight="bold" mt={3}>
                1.3. Uso Adequado e Licenciamento:
              </Text>
              <Text>
                É proibido o uso do sistema para finalidades ilegais ou não autorizadas. Os administradores devem assegurar que
                o sistema seja utilizado exclusivamente para fins educacionais.
              </Text>
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" mt={4}>
            Modelo de Negócio Freemium:
          </Text>
          <UnorderedList spacing={2} pl={4} styleType="none">
            <ListItem mt={4}>
              <Text fontWeight="bold">2.1. Uso Gratuito e Limitações:</Text>
              <Text>
                O sistema oferece uma cota limitada de atividades gratuitas por mês. Detalhes sobre a quantidade exata de
                atividades e o número de alunos suportados estão disponíveis no site. Após o uso dessa cota, um plano pago será
                necessário.
              </Text>
            </ListItem>
            <ListItem mt={4}>
              <Text fontWeight="bold" mt={3}>
                2.2. Termos de Pagamento e Renovação:
              </Text>
              <Text>
                Planos pagos estão sujeitos a renovação automática, a menos que cancelados. Os usuários são responsáveis por
                cancelar suas assinaturas antes da renovação para evitar cobranças adicionais.
              </Text>
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" mt={4}>
            Ferramentas de Inteligência Artificial:
          </Text>
          <UnorderedList spacing={2} pl={4} styleType="none">
            <ListItem mt={4}>
              <Text fontWeight="bold">3.1. Sugestões e Auxílio de Correção:</Text>
              <Text>
                As ferramentas de IA fornecem sugestões e auxiliam na correção de redações. No entanto, a precisão dessas
                sugestões não é garantida, e o usuário deve usar seu julgamento final na avaliação.
              </Text>
            </ListItem>
            <ListItem mt={4}>
              <Text fontWeight="bold" mt={3}>
                3.2. Limitação de Responsabilidade:
              </Text>
              <Text>
                O sistema não se responsabiliza por decisões tomadas com base nas sugestões fornecidas pelas ferramentas de IA.
              </Text>
            </ListItem>
          </UnorderedList>
        </ListItem>
        <ListItem>
          <Text fontWeight="bold" mt={4}>
            Isenção de Responsabilidade e Indenização:
          </Text>
          <UnorderedList spacing={2} pl={4} styleType="none">
            <ListItem mt={4}>
              <Text fontWeight="bold">4.1. Isenção de Responsabilidade:</Text>
              <Text>O sistema não se responsabiliza por qualquer uso indevido ou inadequado por parte dos usuários.</Text>
            </ListItem>
            <ListItem mt={4}>
              <Text fontWeight="bold" mt={3}>
                4.2. Indenização:
              </Text>
              <Text>
                Os usuários concordam em indenizar o sistema por quaisquer danos ou reclamações decorrentes do uso inadequado do
                sistema.
              </Text>
            </ListItem>
          </UnorderedList>
        </ListItem>
      </OrderedList>
    </NoContentContainer>
  )

  const privacyPolicies = (
    <NoContentContainer>
      <OrderedList spacing={4} padding="10px">
        <ListItem>
          <Text fontWeight="bold">Coleta e Uso de Dados Pessoais:</Text>
          <Text>
            Ao utilizar nosso sistema, você consente com a coleta e uso de seus dados pessoais conforme descrito nesta política.
            Os dados podem incluir, mas não se limitam a, informações de identificação pessoal, dados escolares, e conteúdo
            gerado por usuários.
          </Text>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight="bold">Direitos dos Titulares de Dados:</Text>
          <Text>
            Conforme a LGPD, você tem o direito de acessar, corrigir, excluir e portar seus dados pessoais. Para exercer esses
            direitos, entre em contato conosco.
          </Text>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight="bold">Propriedade e Uso dos Dados:</Text>
          <Text>
            Os dados fornecidos ao sistema são processados e controlados pelo Correção Online para as finalidades específicas de
            digitalização, correção, organização e avaliação de redações, conforme descrito nesta Política de Privacidade.
          </Text>
          <Text>O Correção Online pode utilizar os dados coletados para:</Text>
          <UnorderedList pl={4} spacing={2} mt={2} styleType="none">
            <ListItem>
              <Text>Melhorar os serviços oferecidos e a experiência do usuário.</Text>
            </ListItem>
            <ListItem>
              <Text>Desenvolver novas funcionalidades e ferramentas.</Text>
            </ListItem>
            <ListItem>
              <Text>Realizar pesquisas e análises internas.</Text>
            </ListItem>
          </UnorderedList>
          <Text mt={2}>
            Qualquer outro uso dos dados será feito de acordo com o consentimento expresso do titular dos dados. O titular dos
            dados pode, a qualquer momento, solicitar informações sobre o uso de seus dados pessoais, bem como exercer seus
            direitos de acesso, correção, exclusão, portabilidade, restrição de processamento, e revogação de consentimento,
            conforme previsto pela LGPD.
          </Text>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight="bold">Segurança dos Dados:</Text>
          <Text>
            Implementamos medidas de segurança adequadas para proteger seus dados pessoais. No entanto, nenhuma medida de
            segurança é infalível.
          </Text>
        </ListItem>
        <ListItem mt={4}>
          <Text fontWeight="bold">Alterações à Política de Privacidade:</Text>
          <Text>
            Reservamo-nos o direito de alterar esta política a qualquer momento. Você será notificado de quaisquer alterações
            materiais.
          </Text>
        </ListItem>
      </OrderedList>
    </NoContentContainer>
  )

  return (
    <HStack className={'margin-bottom-conditional'} width={'100%'} height={'100%'} justifyContent={'center'}>
      <Box flex={1} height={'100%'} maxW={'750px'} display={{ base: 'none', md: 'block' }}>
        <IlustracaoLogin />
      </Box>
      <Box flex={1} alignSelf={{ md: 'center', base: 'start' }} marginLeft={'12px'} marginTop={{ md: '32px', base: '0px' }}>
        <form onSubmit={handleSubmit(handlingNextStep)}>
          <Box maxWidth={'700px'}>
            <Box alignContent={'start'}>
              <HStack flexDir={{ xl: 'row', md: 'column' }} justifyContent={{ md: 'start', base: 'center' }}>
                <Text className="publicsans-bold-absolute-zero-40px">Cadastre-se</Text>
                <Text className="span2-cadastro" display={{ base: 'none', md: 'block' }}>
                  no Correção Online
                </Text>
              </HStack>
            </Box>
            <div className="content-3-cadastro">
              <HStack className="concluido-cadastro-step-3" ml={{ md: '4px', base: 2 }}>
                <Image width={'42px'} height={'42px'} src="/img/frame-2482@2x.png" alt="Frame 2482" />
                <Frame2486
                  seuPerfil="Seu perfil"
                  text={`${userData.userType === 'Professor' ? 'Professor Independente' : 'Gestor Escolar'}`}
                />
              </HStack>
              <HStack className="step-01-cadastro" mt={2}>
                <SelectedStep number={2} />
                <Text className="criar-conta-cadastro publicsans-normal-nevada-24px" fontSize={{ md: '24px', base: '20px' }}>
                  Informações pessoais
                </Text>
              </HStack>
              <Box paddingRight={'12px'} marginLeft={{ base: '0px', md: '60px' }} className="steps-cadastro">
                <FormControl isInvalid={!!errors.name} mt={4}>
                  <FormLabel className={`${errors.name ? 'error-cadastro' : ''} publicsans-bold-nevada-16px`}>Nome</FormLabel>
                  <HStack>
                    <Input
                      type={'text'}
                      h={'48px'}
                      w={'100%'}
                      backgroundColor={'white'}
                      borderColor={'#d7e3fb'}
                      placeholder="Informe seu nome"
                      {...register('name')}
                    />
                  </HStack>
                  <FormErrorMessage>{errors.name?.message || ''}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.email}>
                  <FormLabel className={`${errors.email ? 'error-cadastro' : ''} publicsans-bold-nevada-16px`}>Email</FormLabel>
                  <Input
                    type="email"
                    h={'48px'}
                    w={'100%'}
                    backgroundColor={'white'}
                    borderColor={'#d7e3fb'}
                    placeholder="nome@email.com.br"
                    {...register('email')}
                  />
                  <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.phoneNumber}>
                  <FormLabel className="publicsans-bold-nevada-16px">Telefone</FormLabel>
                  <Input
                    type="text"
                    h={'48px'}
                    w={'100%'}
                    backgroundColor={'white'}
                    borderColor={'#d7e3fb'}
                    id="phoneNumber"
                    placeholder="(81) 99999-9999"
                    {...register('phoneNumber')}
                    as={InputMask}
                    mask={inputPhoneMask}
                  />
                  <FormErrorMessage>{errors.phoneNumber?.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.eula}>
                  <HStack>
                    <Checkbox id="eula" {...register('eula')} />
                    <FormLabel m={0} htmlFor="eula" color="gray.500">
                      Aceito todos os{' '}
                      <Link
                        color="gray.700"
                        onClick={(event) => {
                          event.preventDefault()
                          onTermsOpen()
                        }}>
                        Termos de uso
                      </Link>{' '}
                      e{' '}
                      <Link
                        color="gray.700"
                        onClick={(event) => {
                          event.preventDefault()
                          onPrivacyOpen()
                        }}>
                        Políticas de privacidade
                      </Link>
                    </FormLabel>
                  </HStack>
                  <FormErrorMessage>{errors.eula?.message}</FormErrorMessage>
                </FormControl>
              </Box>
              <Box mt={7} className="steps-cadastro">
                <UnselectedStep number="3" text="Informações Profissionais" />
              </Box>
              <HStack
                marginTop={{ md: '32px', base: '0' }}
                flexDir={{ base: 'column-reverse', md: 'row' }}
                width={'100%'}
                marginBottom={'16px'}
                paddingRight={'12px'}>
                <Button
                  onClick={handleReturn}
                  width={'100%'}
                  height={'48px'}
                  variant="outline"
                  fontSize={'m'}
                  sx={FontsUtils.publicSansBoldAbsoluteZero}>
                  Voltar
                </Button>
                <Button
                  type={'submit'}
                  width={'100%'}
                  height={'48px'}
                  variant="primary"
                  fontSize={'m'}
                  sx={FontsUtils.publicSansBoldWhite}>
                  Continuar
                </Button>
              </HStack>
            </div>
          </Box>
        </form>
      </Box>

      <Modal isOpen={isTermsOpen} onClose={onTermsClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: '95%', md: '600px', lg: '800px' }}>
          <ModalHeader color={'brand.200'}>Termos de Uso</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" maxH="70vh">
            {userData.userType === 'Professor' ? termsOfUseTeacher : termsOfUseAdm}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isPrivacyOpen} onClose={onPrivacyClose}>
        <ModalOverlay />
        <ModalContent maxW={{ base: '95%', md: '600px', lg: '800px' }}>
          <ModalHeader color={'brand.200'}>Políticas de Privacidade</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflowY="auto" maxH="70vh">
            {privacyPolicies}
          </ModalBody>
        </ModalContent>
      </Modal>
    </HStack>
  )
}

function Frame2486(props) {
  const { seuPerfil, text, className } = props

  return (
    <div className={`frame-2486-cadastro-step-3 ${className || ''}`}>
      <div className="seu-perfil-cadastro-step-3 publicsans-normal-nevada-20px">{seuPerfil}</div>
      <div className="text-cadastro-step-3 publicsans-normal-nevada-16px">{text}</div>
    </div>
  )
}

export default SignUpStep2
