import { sleep } from '@/pages/EssayTeacher/utils.ts'
import {
  useDeleteApiV1AssistantAssistantPromptId,
  usePostApiV1AssistantAssistantPrompt,
  usePutApiV1AssistantAssistantPrompt,
} from '@/services/assistant.ts'
import type { AssistantPromptViewModel } from '@/services/types'
import {
  Badge,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import type { AxiosError } from 'axios'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { variables } from './promptConstants'

interface PromptModalBodyProps {
  prompt: AssistantPromptViewModel | null
  onClose: () => void
  fetchPrompts: () => void
}

interface PromptFormValues {
  title: string
  systemContent: string
  mainContent: string
}

const validationSchema = yup.object().shape({
  title: yup.string().min(10).required(),
  systemContent: yup.string().min(10).required(),
  mainContent: yup.string().min(15).required(),
})

export function EssayPromptModalBody({ prompt, onClose, fetchPrompts }: PromptModalBodyProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<PromptFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: prompt?.title || '',
      systemContent: prompt?.systemContent || '',
      mainContent: prompt?.content || '',
    },
  })

  const title = watch('title')
  const systemContent = watch('systemContent')
  const mainContent = watch('mainContent')

  const enableCreationButton = useMemo(
    () => !errors.title && !errors.systemContent && !errors.mainContent,
    [errors, title, systemContent, mainContent],
  )

  const { mutateAsync: createPrompt, isPending: isCreationPending } = usePostApiV1AssistantAssistantPrompt<AxiosError>({
    mutation: {
      onSuccess: () => {
        fetchPrompts()
        toast.success('Prompt criado com sucesso!')
        onClose()
      },
    },
  })

  const { mutateAsync: updatePrompt, isPending: isUpdatePending } = usePutApiV1AssistantAssistantPrompt<AxiosError>({
    mutation: {
      onSuccess: () => {
        fetchPrompts()
        toast.success('Prompt alterado com sucesso!')
        onClose()
      },
    },
  })

  const { mutateAsync: deletePrompt, isPending: isDeletePending } = useDeleteApiV1AssistantAssistantPromptId<AxiosError>({
    mutation: {
      onSuccess: () => {
        fetchPrompts()
        toast.success('Prompt removido com sucesso!')
        onClose()
      },
    },
  })

  const handleVariableClick = async (variableKey: string) => {
    const textToInsert = `[[${variableKey}]]`
    const currentText = watch('mainContent')
    const cursorPosition = currentText?.length || 0
    const newText = currentText.slice(0, cursorPosition) + textToInsert + currentText.slice(cursorPosition)

    setValue('mainContent', newText)

    await sleep(500)
  }

  const onSubmit = async (data: PromptFormValues) => {
    const newPromptData = {
      title: data.title,
      systemContent: data.systemContent,
      content: data.mainContent,
      isDefault: false,
      id: prompt?.id,
    }

    try {
      prompt ? await updatePrompt({ data: newPromptData }) : await createPrompt({ data: newPromptData })
    } catch (error) {
      console.error('Erro ao criar prompt', error)
    }
  }

  function handleDeletePrompt() {
    if (!prompt?.id) return
    deletePrompt({ id: prompt?.id })
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <VStack gap={5}>
        <FormControl isInvalid={!!errors.title}>
          <FormLabel>Título</FormLabel>
          <Input {...register('title')} />
          <FormErrorMessage>{errors.title?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.systemContent}>
          <FormLabel>Contexto</FormLabel>
          <Textarea {...register('systemContent')} />
          <FormErrorMessage>{errors.systemContent?.message}</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors.mainContent}>
          <FormLabel>Prompt Principal</FormLabel>
          <Textarea minHeight="300px" {...register('mainContent')} />
          <FormErrorMessage>{errors.mainContent?.message}</FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>Inserir Variáveis</FormLabel>
          <Stack alignItems="start" spacing={2}>
            {variables.map((variable) => (
              <Badge
                cursor="pointer"
                fontSize="14px"
                variant="outline"
                colorScheme="purple"
                key={variable.key}
                onClick={() => handleVariableClick(variable.key)}>
                {variable.description}
              </Badge>
            ))}
          </Stack>
        </FormControl>
      </VStack>
      {prompt ? (
        <HStack pt={5} justifyContent={'space-between'}>
          <Button float="right" color={'red'} onClick={handleDeletePrompt} isLoading={isDeletePending}>
            Apagar prompt
          </Button>
          <Button
            float="right"
            variant="primary"
            type="submit"
            isLoading={isUpdatePending}
            isDisabled={!enableCreationButton || isDeletePending}>
            Atualizar prompt
          </Button>
        </HStack>
      ) : (
        <Button float="right" variant="primary" type="submit" isLoading={isCreationPending} isDisabled={!enableCreationButton}>
          Criar prompt
        </Button>
      )}
    </Box>
  )
}
