import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  TabPanel,
  Text,
  VStack,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { getApiV1StudentStudentCheckStudentEmail, postApiV1Student } from '../../services/student'

const validationSchema = yup.object({
  name: yup.string().min(5).max(255).required(),
  email: yup.string().email().required(),
})

type Form = yup.InferType<typeof validationSchema>

type NovoAlunoFormProps = {
  turmaID: string
  schoolId: string
  fetchStudents: () => Promise<void>
}

const inputStyle = {
  width: '100%',
  backgroundColor: 'white',
  border: '1px solid #d7e3fb',
  padding: '5px',
  paddingLeft: '12px',
}

export const NewStudentForm = (props: NovoAlunoFormProps) => {
  const { turmaID, schoolId, fetchStudents } = props
  const [isNameDisabled, setIsNameDisabled] = useState(false)
  const [isCheckingEmail, setIsCheckingEmail] = useState(false)
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<Form>({ resolver: yupResolver(validationSchema) })

  async function submit(body: Form) {
    const requestBody = {
      ...body,
      groups: [turmaID],
      schoolId,
      language: localStorage.getItem('user_language'),
    }

    try {
      await postApiV1Student(requestBody)
      await fetchStudents()
      reset()
      toast.success(`${body.email} adicionado!`)
    } catch (error) {
      console.error('Erro ao adicionar aluno:', error)
    }
  }

  const checkIfEmailExist = async (email = '') => {
    try {
      setIsCheckingEmail(true)
      const response = await getApiV1StudentStudentCheckStudentEmail(email)
      const name = response.data.message?.name
      setValue('name', name || '')
      setIsNameDisabled(!!name)
      setIsCheckingEmail(false)
    } catch (error) {
      console.error('Erro ao buscar se email:', error)
    }
  }

  return (
    <TabPanel>
      <Text paddingBottom={'16px'} paddingTop={'8px'} paddingLeft={'8px'}>
        O aluno receberá um email para confirmar cadastro.
      </Text>
      <form onSubmit={handleSubmit(submit)}>
        <VStack>
          <FormControl isInvalid={errors.email && !!errors.email.message}>
            <Input
              id="email"
              type="text"
              style={inputStyle}
              placeholder={'email@dominio.com'}
              className="input-default-style"
              {...register('email')}
              onBlur={(e) => checkIfEmailExist((e.target as HTMLInputElement).value)}
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={errors.name && !!errors.name.message}>
            <InputGroup>
              <Input
                className="input-default-style"
                id="nome"
                style={inputStyle}
                placeholder={'Nome do Aluno'}
                type="text"
                isDisabled={isNameDisabled || isCheckingEmail}
                {...register('name')}
              />
              {isCheckingEmail && (
                <InputRightElement height="100%">
                  <Spinner size="sm" color="blue.500" />
                </InputRightElement>
              )}
            </InputGroup>
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            backgroundColor={'#0A41D6'}
            color={'white'}
            width={'80%'}
            height={'48px'}
            marginTop={'15px'}
            isDisabled={isCheckingEmail}
            isLoading={isSubmitting}>
            Adicionar
          </Button>
        </VStack>
      </form>
    </TabPanel>
  )
}
