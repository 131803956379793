import { Grades } from '@/components/Grades'
import { useGetApiV1ActivityActivityId } from '@/services/activity'
import { usePutApiV1EssayEssayIdUpdateGrades } from '@/services/essay'
import { useGetApiV1SchoolSchoolIdInstitutionInstitutionId } from '@/services/school'
import type { CompleteEssayViewModel, EssayCompetencyViewModel, ListOfEssayCompetenciesViewModel } from '@/services/types'
import useLoggedUserStore from '@/store/useLoggedUserStore'
import { calculateTotalGrades } from '@/utils/calculateTotalGrades'
import { institutionGradeTypeEnum } from '@/utils/institutionGradeTypes'
import { useAddToHistory } from '@/utils/useNavigationHooks.ts'
import { Alert, AlertIcon, Avatar, Box, Center, CloseButton, HStack, Heading, Stack, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import { type ChangeEvent, useState } from 'react'
import { toast } from 'react-toastify'
import { EssayReviewSidebar, useEssayReviewSidebar } from '../../components/EssayArea/useEssayReviewSidebar'
import { essayStatus, returnEssayStatusClassName } from '../../utils/essayStatus'
import { EssayActions } from './EssayActions'

type EssaySidebarProps = {
  essay: CompleteEssayViewModel
  competencies: EssayCompetencyViewModel[]
  setCompetencies: (compentence: EssayCompetencyViewModel[]) => void
  isAbleToReview: boolean
  refetchEssay: () => Promise<number | string | undefined>
}
export const EssaySidebar = (props: EssaySidebarProps) => {
  const { competencies: competenciesData, essay, isAbleToReview, refetchEssay } = props
  const [competencies, setCompetencies] = useState(competenciesData)
  const { loggedUser } = useLoggedUserStore()
  const { isSidebarOpen, toogleSidebar } = useEssayReviewSidebar()

  const getInstituition = useGetApiV1SchoolSchoolIdInstitutionInstitutionId(loggedUser.schoolId, essay.institution?.id || '')
  const institution = getInstituition.data?.data.message
  const getActivityAPI = useGetApiV1ActivityActivityId(essay?.activity?.id || '')
  const activity = getActivityAPI.data?.data.message
  const studentName = activity?.essays?.find((e) => e.id === essay.id)?.student?.name || 'Indisponível'
  const updateGradesAPI = usePutApiV1EssayEssayIdUpdateGrades()
  const [hasGradesChanged, setHasGradesChanged] = useState(false)
  const [isGradesInvalid, setIsGradesInvalid] = useState(false)

  useAddToHistory()

  const isTotaGradeValid = (newTotalGrade: number, competencies: EssayCompetencyViewModel[]) => {
    if (institution?.institutionGradeType === institutionGradeTypeEnum.AVERAGE) {
      const averageGrade = newTotalGrade / competencies.length
      const isAverageGradeValid = averageGrade > 0 && averageGrade <= (institution.maxGrade || 0)
      if (!isAverageGradeValid) toast.error(`A média das notas deve ser menor ou igual a ${institution.maxGrade}`)
      return isAverageGradeValid
    }

    if (institution?.institutionGradeType === institutionGradeTypeEnum.SUM) {
      const isSumGradeValid = newTotalGrade > 0 && newTotalGrade <= (institution.maxGrade || 0)
      if (!isSumGradeValid) toast.error(`A soma das notas deve ser igual ou menor que ${institution.maxGrade}`)
      return isSumGradeValid
    }
  }

  const setNewGrade = async (e: ChangeEvent<HTMLInputElement>) => {
    const id = e.target.id
    const grade = Number(e.target.value)
    if (Number.isNaN(grade)) {
      setIsGradesInvalid(true)
      return toast.error('Insira uma nota válida')
    }

    let newCompetenciesGrade = competencies.map((competence) => (competence.id === id ? { ...competence, grade } : competence))

    newCompetenciesGrade = newCompetenciesGrade.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i)
    setCompetencies(newCompetenciesGrade)
    const newTotalGrade = calculateTotalGrades(newCompetenciesGrade)

    const isGradeValid = isTotaGradeValid(newTotalGrade, newCompetenciesGrade)
    if (!isGradeValid) return setIsGradesInvalid(true)
    setHasGradesChanged(true)
    setIsGradesInvalid(false)
  }

  const submitGrades = async () => {
    const data: ListOfEssayCompetenciesViewModel = {
      grades: competencies,
      finalGrade: calculateTotalGrades(competencies),
    }
    updateGradesAPI.mutate({ essayId: essay.id || '', data }, { onSuccess: () => setHasGradesChanged(false) })
  }

  if (!essay || !activity || !institution) return
  return (
    <Box
      width={{ base: '100%', md: '300px' }}
      minW={'300px'}
      height="100%"
      bgColor="white"
      right={0}
      top={0}
      overflowY="auto"
      display={{ base: isSidebarOpen ? 'block' : 'none', lg: 'flex' }}
      position={{ base: 'fixed', lg: 'static' }}
      zIndex={2}>
      <CloseButton
        display={{ lg: 'none' }}
        position="absolute"
        right={2}
        top={2}
        onClick={() => toogleSidebar(EssayReviewSidebar.DETAILS)}
      />
      <Stack width="100%" spacing={6} p={7}>
        <Stack spacing={1}>
          <Heading fontSize="sm">Status da redação</Heading>
          <Center className={`${returnEssayStatusClassName(essay.status)}`} w={'100%'}>
            <Text fontSize="sm">{essayStatus[essay.status].label}</Text>
          </Center>
        </Stack>

        <Stack spacing={1}>
          <Heading fontSize="sm">Aluno</Heading>
          <HStack spacing={4}>
            <Avatar name={studentName} />
            <Stack gap={0.5}>
              <Text fontSize="sm">{studentName}</Text>
              <Box
                px={3}
                py={1}
                borderRadius="lg"
                background="linear-gradient(28deg, rgba(2, 182, 128, 0.16) 0%, rgba(33, 253, 187, 0.06) 57.13%, rgba(33, 253, 187, 0.02) 100%)">
                <Text fontSize="xs">{essay.institution?.name}</Text>
              </Box>
            </Stack>
          </HStack>
        </Stack>

        <HStack flexDir={{ base: 'column', sm: 'row' }} alignItems={'start'}>
          <Stack spacing={1} flexGrow={1} maxW="200px">
            <Heading fontSize="sm">Atividade</Heading>
            <Text fontSize="sm" color="#828282">
              {essay?.activity?.name}
            </Text>
          </Stack>

          <Stack spacing={1}>
            <Heading fontSize="sm">Entrega</Heading>
            <Text fontSize="sm" color="#828282">
              {essay?.deadline && format(new Date(essay.deadline), 'dd/MM/yyyy')}
            </Text>
          </Stack>
        </HStack>

        <Stack spacing={1}>
          <Heading fontSize="sm">Tema</Heading>
          <Text fontSize="sm" color="#828282">
            {essay.theme?.name}
          </Text>
        </Stack>

        {competencies.length > 0 ? (
          <Stack spacing={1}>
            <Grades
              isAbleToReview={isAbleToReview}
              competencies={competencies}
              setNewGrade={setNewGrade}
              submitGrades={submitGrades}
              isSavingGrades={updateGradesAPI.isPending}
              hasGradesChanged={hasGradesChanged}
              isGradesInvalid={isGradesInvalid}
              totalGrade={calculateTotalGrades(competencies)}
            />
          </Stack>
        ) : (
          <Alert status="error">
            <AlertIcon />
            Essa redação não possui competências para avaliar. Entre em contato com o administrador do sistema para obter mais
            informações.
          </Alert>
        )}
        <EssayActions essay={essay} refetchEssay={refetchEssay} isGradesInvalid={hasGradesChanged || isGradesInvalid} />
      </Stack>
    </Box>
  )
}
