import { Box, Image, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import fontsUtils from '../theme/foundations/FontsUtils'
import { essayStatus, returnEssayStatusClassName } from './essayStatus'
import { formatDate, formatTime } from './formatDate'

export function HandleSubmittedDate({ dateSubmitted }) {
  let date = ''
  if (dateSubmitted) {
    date = `${formatDate(dateSubmitted)} às ${formatTime(dateSubmitted)}`
  }
  return (
    <Box justifyContent={'center'} className={'table-cell-container publicsans-normal-black-12px'}>
      {date && (
        <img className="calendar-2851774-1-detalhamento-atividade" src={'/img/calendar-icon.svg'} alt="calendar-2851774" />
      )}
      {date}
    </Box>
  )
}

export function HandleStatusInput({ status }) {
  const backgroundColor = returnEssayStatusClassName(status)

  return (
    <div className={'atividade-txt-detalhamento-atividade publicsans-normal-black-12px'}>
      <div className={backgroundColor}>{essayStatus[status]?.label}</div>
    </div>
  )
}

export function HandleGroup({ groupName }) {
  return (
    <div className={'table-cell-container publicsans-normal-black-12px'}>
      <Text color={fontsUtils.fontGray.color}> {groupName} </Text>
    </div>
  )
}

export function HandleEssayClickTeacher({ essayId }) {
  const navigate = useNavigate()

  return (
    <Box className={'publicsans-normal-black-12px'}>
      <Box
        onClick={() => {
          navigate(`/redacao-professor/${essayId}`)
        }}>
        <Box className="atividade-txt-4-area-de-turmas">
          <Image className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
        </Box>
      </Box>
    </Box>
  )
}

export function HandleEssayClickStudent({ essayId }) {
  const navigate = useNavigate()

  return (
    <Box cursor={'pointer'} className={'publicsans-normal-black-12px'}>
      <Box
        onClick={() => {
          navigate(`/redacao-estudante/${essayId}`)
        }}>
        <Box className="atividade-txt-4-area-de-turmas">
          <Image className="ic-arrowright-style-detalhamento" src={'/img/ic-arrowright@2x.png'} alt="ic-arrowright" />
        </Box>
      </Box>
    </Box>
  )
}
