import { Box, Button, HStack, Image, Text, VStack } from '@chakra-ui/react'
import IlustracaoLogin from '../../components/IlustracaoLogin'

const SignUpStep4 = (props: { handlingNextStep }) => {
  const { handlingNextStep } = props
  return (
    <HStack className={'margin-bottom-conditional'} width="100%" height="100%" justifyContent="center">
      <Box flex={1} height="100%" maxW="750px" display={{ base: 'none', md: 'block' }}>
        <IlustracaoLogin />
      </Box>
      <Box
        flex={1}
        alignSelf={{ md: 'center', base: 'start' }}
        marginLeft="12px"
        marginRight={'12px'}
        marginTop={{ md: '32px', base: '0px' }}>
        <VStack align="center" maxW="700px">
          <Text className={'publicsans-bold-absolute-zero-40px'} mt={4} fontSize={{ md: '40px', base: '30px' }}>
            Cadastro realizado!
          </Text>
          <Text className={'publicsans-normal-nevada-20px'} mt={2} fontSize={{ md: '20px', base: '18px' }}>
            Confirme sua conta através do e-mail de confirmação.
          </Text>
          <Image src="/img/concluido-icon.svg" alt="Concluído" w={{ base: '80%', md: '50%' }} />
          <HStack width="100%">
            <Button onClick={handlingNextStep} variant="primary" width="100%" height="48px">
              Voltar para o login
            </Button>
          </HStack>
        </VStack>
      </Box>
    </HStack>
  )
}

export default SignUpStep4
