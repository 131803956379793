import { Modal } from '@/components/modal'
import {
  putApiV1EssayEssayIdCloseReview,
  putApiV1EssayEssayIdFinishReview,
  usePutApiV1EssayReturnEssayToDraft,
} from '@/services/essay'
import type { CompleteEssayViewModel } from '@/services/types'
import useNavigationStoreHistory from '@/store/useNavigationStoreHistory.ts'
import { useBackNavigation } from '@/utils/useBackNavigation.ts'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Portal,
  Tooltip,
  VStack,
  useDisclosure,
} from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { IoIosSend } from 'react-icons/io'
import { IoMdCheckmark } from 'react-icons/io'
import { MdOutlineReplay } from 'react-icons/md'
import { toast } from 'react-toastify'
import * as yup from 'yup'

const essayIDErrorMessage = 'URL faltando ID da dissertação: essayId'

const validationSchema = yup.object({
  reason: yup.string().min(5).max(500).required(),
})

const inputStyle = {
  width: '100%',
  backgroundColor: 'white',
  border: '1px solid #d7e3fb',
  padding: '25px 20px',
}

type EssayActionsProps = {
  essay: CompleteEssayViewModel
  refetchEssay: () => Promise<number | string | undefined>
  isGradesInvalid?: boolean
}

export const EssayActions = (props: EssayActionsProps) => {
  const { essay, refetchEssay, isGradesInvalid } = props
  const goBack = useNavigationStoreHistory((state) => state.goBack)
  const handleBackNavigation = useBackNavigation()

  const [actionLoading, setActionLoading] = useState(false)
  const { isOpen: returnReviewIsOpen, onOpen: returnReviewOnOpen, onClose: returnReviewOnClose } = useDisclosure()
  const { isOpen: finishReviewIsOpen, onOpen: finishReviewOnOpen, onClose: finishReviewOnClose } = useDisclosure()
  const { isOpen: closeReviewIsOpen, onOpen: closeReviewOnOpen, onClose: closeReviewOnClose } = useDisclosure()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) })

  const handleReturnSuccess = async () => {
    handleBackNavigation()
    goBack()
  }
  const returnEssay = usePutApiV1EssayReturnEssayToDraft({
    mutation: { onSuccess: handleReturnSuccess },
  })

  async function sendReview() {
    if (!essay.id) return toast.error(essayIDErrorMessage)

    try {
      setActionLoading(true)
      await putApiV1EssayEssayIdFinishReview(essay.id)
      await refetchEssay()
      finishReviewOnClose()
    } catch (error) {
      console.error(error)
    } finally {
      setActionLoading(false)
    }
  }

  async function closeReview() {
    if (isGradesInvalid) return toast.error('Salve as notas antes de finalizar')
    if (!essay.id) return toast.error(essayIDErrorMessage)

    try {
      setActionLoading(true)
      await putApiV1EssayEssayIdCloseReview(essay.id)
      await refetchEssay()
      closeReviewOnClose()
    } catch (error) {
      console.error(error)
    } finally {
      setActionLoading(false)
    }
  }

  return (
    <>
      {essay.status === 20 && (
        <VStack width="100%">
          <Button
            width="100%"
            size="sm"
            leftIcon={<MdOutlineReplay />}
            variant="outline"
            onClick={returnReviewOnOpen}
            padding={5}>
            Devolver
          </Button>
          <Tooltip label="Salve as notas antes de enviar" isDisabled={!isGradesInvalid}>
            <Button
              width="100%"
              variant="primary"
              isDisabled={isGradesInvalid}
              leftIcon={<IoIosSend />}
              onClick={finishReviewOnOpen}
              padding={6}>
              Enviar correção
            </Button>
          </Tooltip>
        </VStack>
      )}
      {essay.status === 30 && (
        <Tooltip label="Salve as notas antes de enviar" isDisabled={!isGradesInvalid}>
          <Button
            width="100%"
            variant="primary"
            isDisabled={isGradesInvalid}
            leftIcon={<IoMdCheckmark />}
            onClick={closeReviewOnOpen}
            padding={6}>
            Finalizar
          </Button>
        </Tooltip>
      )}
      <Portal>
        <Modal
          title={'Devolver redação?'}
          isOpen={returnReviewIsOpen}
          onClose={returnReviewOnClose}
          submitLabel={'Devolver'}
          isLoading={returnEssay.isPending}
          onSubmit={handleSubmit((form) => returnEssay.mutate({ data: { essayId: essay.id, reason: form.reason } }))}
          body={
            <FormControl isInvalid={Boolean(errors.reason?.message)}>
              <FormLabel>Comentários e notas serão removidos. Deseja continuar?</FormLabel>
              <Input
                id="reason"
                {...register('reason')}
                style={inputStyle}
                borderColor="brand.300"
                mt={2}
                placeholder="Motivo da devolução"
              />
              <FormErrorMessage>{errors.reason?.message}</FormErrorMessage>
            </FormControl>
          }
        />
        <Modal
          title={'Enviar revisão'}
          body={'O aluno poderá visualizar comentários e notas. Deseja continuar?'}
          isOpen={finishReviewIsOpen}
          onClose={finishReviewOnClose}
          submitLabel={'Enviar'}
          isLoading={actionLoading}
          onSubmit={sendReview}
        />
        <Modal
          title={'Finalizar revisão'}
          body={'Nenhuma alteração poderá ser feita após finalizar a correção. Deseja continuar?'}
          isOpen={closeReviewIsOpen}
          onClose={closeReviewOnClose}
          submitLabel={'Finalizar correção'}
          isLoading={actionLoading}
          onSubmit={closeReview}
        />
      </Portal>
    </>
  )
}
