import { create } from 'zustand'

interface EssayStudentCreateStore {
  draft: string
  setDraft: (draft: string) => void
}

const useEssayStudentCreateStore = create<EssayStudentCreateStore>((set) => ({
  draft: '',
  setDraft: (draft: string) => set({ draft }),
}))

export default useEssayStudentCreateStore
